import { observer } from 'mobx-react';
import React from 'react';
import { Entity } from '../../entities/models/entity';
import { EntitySettings, EntitySettingsParams, IntentTrigger } from '../models/triggers/IntentTrigger';
import { action } from 'mobx';
import cn from './Params.module.scss';
import cns from 'classnames';
import { WithTranslation, withTranslation } from 'react-i18next';


interface EntityEditProps extends WithTranslation {
    entities: Array<Entity>;
    trigger: IntentTrigger;
}


@observer
export class EntityEditComponent extends React.Component<EntityEditProps> {
    onChange(entity: Entity, extraction: 'required' | 'optional') {
        this.props.trigger.changeEntitySettings({entity, settings: extraction as EntitySettingsParams});
    }

    @action.bound renderEntitySetting = (entitySetting: EntitySettings, i: number) => {
        const entity = this.props.entities.find(e => entitySetting.entity.id === e.id);
        return <label className={cn.label} key={i}>
            <select onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                const extraction = e.target.value === 'required' ? e.target.value : 'optional';
                this.onChange(entity!, extraction);
            }} defaultValue={entitySetting.settings} className={cns(cn.input, cn.entitySelector)}>
                <option value="required">{entity!.name} { this.props.t('required') }</option>
                <option value="optional">{entity!.name} { this.props.t('optional') }</option>
            </select>
        </label>
    }

    render() {
        return <div style={{marginTop: 22}}>
            {this.props.trigger.entitySettings.map(this.renderEntitySetting)}
        </div>;
    }
}

export const EntityEdit = withTranslation()(EntityEditComponent);
