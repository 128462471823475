import React, { FC, useEffect } from 'react';
import { Channel } from '../../models/channel';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { ChannelEditStore } from '@/app/connects/components/ConnectPageContent';
import { Form, InputNumber, Input } from 'antd';
import { useMaxLength, useRegExp, useRequired } from '@/common/utils/form-validators';

type UsedeskProps = {
    channel: Channel;
    channelEditStore: ChannelEditStore;
    isManageChannels: boolean;
    onFinish: (values: any) => void;
    isRequest: boolean;
}



export const Usedesk: FC<UsedeskProps> = observer(({
                                                                                   channel,
                                                                                   isManageChannels,
                                                                                   channelEditStore,
                                                                                   onFinish,
                                                                                   isRequest
                                                                               }) => {
    const {t} = useTranslation();
    const [form] = Form.useForm();
    channelEditStore.setCurrentEditForm(form);
    const onFinishForm = (values: any) => {
        onFinish(values);
    }

    useEffect(() => form.resetFields(), [channel.id]);

    const required = useRequired();
    const maxLength = useMaxLength(4096);
    const maxLength128 = useMaxLength(128);
    const startsWithQaIntentValidator = useRegExp(/^qa-.+|intent-.+/, 'qa- / intent-');

    const initialValues = {
        usedesk_token: channel.params.usedesk_token,
        usedesk_operator: channel.params.usedesk_operator,
        usedesk_group: channel.params.usedesk_group,
        max_message_len: channel.params.max_message_len,
        long_text_intent: channel.params.long_text_intent,
        survey_intent: channel.params.survey_intent
    };

    return <Form form={form} style={{maxWidth: '600px'}} layout="vertical" onFinish={onFinishForm}
                 initialValues={initialValues}>

        <Form.Item rules={[required, maxLength]} name="usedesk_token"
                   label={t('connect.usedesk.input_label_token')}>
            <Input disabled={!isManageChannels || isRequest}/>
        </Form.Item>

        <Form.Item rules={[required]} name="usedesk_operator" label={t('connect.usedesk.input_label_operator')}>
            <InputNumber disabled={!isManageChannels || isRequest}/>
        </Form.Item>

        <Form.Item rules={[required]} name="usedesk_group"
                   label={t('connect.usedesk.input_label_group')}>
            <InputNumber disabled={!isManageChannels || isRequest}/>
        </Form.Item>

        <Form.Item rules={[required]} name="max_message_len"
                   label={t('connect.usedesk.max_message_len')}>
            <InputNumber min={2} disabled={!isManageChannels || isRequest}/>
        </Form.Item>

        <Form.Item rules={[required, maxLength128, startsWithQaIntentValidator]} name="long_text_intent"
                   label={t('connect.usedesk.long_text_intent')}>
            <Input disabled={!isManageChannels || isRequest}/>
        </Form.Item>

        <Form.Item rules={[required, maxLength128, startsWithQaIntentValidator]} name="survey_intent"
                   label={t('connect.usedesk.survey_intent')}>
            <Input disabled={!isManageChannels || isRequest}/>
        </Form.Item>
    </Form>
});


