import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';
import cls from 'classnames';
import cn from './Header.module.scss'

import { HeaderProject } from './HeaderProject';
import { UserStore } from '@/core/stores/user.store';
import { ReactComponent as DocsIcon } from './imgs/docs.svg';
import { Dropdown, MenuProps } from 'antd';

type HeaderProps = {
    user: UserStore;
    isMinimal?: boolean;
}

export const HeaderComponent = ({ user, isMinimal }: HeaderProps) => {
    const navigate = useNavigate();
    const {t} = useTranslation();

    const params = useParams();

    const { userInfo } = user;
    const moveToProfile = () => {
        navigate(`/app/${params.projectId}/profile`);
    };

    const moveToActivityLogs = () => {
        navigate(`/app/${params.projectId}/activity-logs`)
    };

    const moveToVariables = () => {
        navigate(`/app/${params.projectId}/variables`);
    }

    const moveToTeamMembers = (invite?: boolean) => {
        // if (invite) {
        //     setSearchParams('invite=true');
        // }
        // navigate(`/app/${params.projectId}/team`, {
        //
        // })

        navigate({
            pathname: `/app/${params.projectId}/team`,
            search: invite ? 'invite=true' : ''
        });
    }

    const signOut = async () => {
        await user.logout();
        navigate('/auth');
    };

    const num = (Math.abs(userInfo.id) % 8) + 1;
    const avatarClass = cn[`headerProfileIcon--bg-${num}`];
    const firstName = userInfo.first_name;
    const lastName = userInfo.last_name;
    const smallName = `${firstName && firstName[0]}${lastName && lastName[0]}`;

    const items: MenuProps['items'] = [
        {
            label: `${firstName} ${lastName}`,
            key: '0',
            disabled: true
        },
        {
            type: 'divider'
        },
        {
            label: t('profile_select.profile_settings'),
            onClick: moveToProfile,
            key: '1',
        },
        {
            label: t('profile_select.members'),
            onClick: () => moveToTeamMembers(),
            key: '2',
            disabled: !user.permissions.isManageTeam,
        },
        {
            label: t('profile_select.activity'),
            onClick: moveToActivityLogs,
            key: '3',
        },
        {
            label: t('profile_select.variables'),
            onClick: moveToVariables,
            key: '4',
        },
        {
            type: 'divider',
        },
        {
            label: 'Выход',
            onClick: signOut,
            key: '5',
        },
    ];

    return (
        <div className={cls(cn.header, isMinimal && cn.headerMinimal)}>
            <Link to={'/'} aria-label={'Move to home'} className={cn.logo}/>
            <div className={cn.headerProject}>
                {!isMinimal && <HeaderProject />}
            </div>
            <div className={cn.headerSpacer} />
            <div className={cn.headerControls}>
                <a
                    className={cn.headerControlsDocs}
                    href="https://liachat.gitbook.io/docs/"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <DocsIcon />
                </a>
                <div className={cn.headerControlsDivider} />
            </div>

            <Dropdown menu={{ items }} trigger={['click']}>
                <div className={cls(cn.headerProfileIcon, avatarClass)}>{smallName}</div>
            </Dropdown>

        </div>
    );
};
 export default HeaderComponent;
