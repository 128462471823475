import { makeObservable, observable } from 'mobx';
import { SnippetDto } from '@/app/snippets/models/snippet.dto';
import { SaveButtonState } from '@/common/components/save-button/SaveButton';
import { JsTemplate } from '@/app/snippets/models/js-template';

interface JsTemplateWithRef extends JsTemplate {
    ref: HTMLSpanElement | null
}
export class SnippetEditStore {
    static parseExecutionResult(result: any) {
        return ['string'].includes(typeof result) || result === null ? result : JSON.stringify(result);
    }

    static getJavaScriptWrapper(code = ''): string {
        return `
async function snippet (core = new Core(), context = new Context()) {
${code}
}
`.trim();
    }
    // @ts-ignore
    @observable snippet: SnippetDto = {
        value: {}
    };
    @observable saveState: SaveButtonState = SaveButtonState.default;
    @observable titlesByState: Record<SaveButtonState, string> = {
        [SaveButtonState.default]: 'snippets.save',
        [SaveButtonState.process]: 'actions.saving',
        [SaveButtonState.saved]: 'actions.saved',
        [SaveButtonState.error]: 'actions.error'
    };
    @observable executionResults = observable([]);
    @observable isExecutionBarOpened = false;

    editor: any;
    snippets: JsTemplateWithRef[] = [];
    prevState: string = '';
    snippetCode: string = SnippetEditStore.getJavaScriptWrapper();

    get isChanged() {
        return this.snippetCode !== this.prevState;
    }
    constructor() {
        makeObservable(this);
    }

    get codeLines() {
        const codeLines = this.snippetCode.split('\n');
        codeLines.splice(0, 1);
        codeLines.splice(codeLines.length - 1, 1);
        return codeLines.join('\n');
    }
}
