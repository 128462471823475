import * as React from 'react';
import { Reaction } from '../models/reactions/Reaction';
import { TextReaction } from '../models/reactions/TextReactions';
import { TextReactionNode } from './TextReactionNode';
import { JumpToReaction } from '../models/reactions/JumpToReaction';
import { JumpToReactionNode } from './JumpToReactionNode';
import { ButtonsReaction } from '../models/reactions/ButtonsReaction';
import { ButtonsReactionView } from './ButtonsReactionView';
import { SnippetReaction } from '../models/reactions/SnippetReaction';
import { SnippetReactionView } from './SnippetReactionView';
import { RepeatReaction } from '../models/reactions/RepeatReaction';
import { RepeatReactionView } from './RepeatReactionView';
import { ResetReaction } from '../models/reactions/ResetReaction';
import { ResetReactionView } from './ResetReactionView';
import { TerminateReaction } from '../models/reactions/TerminateReaction';
import { TerminateReactionView } from './TerminateReactionView';
import { FileReaction } from '../models/reactions/FileReaction';
import { FileReactionView } from './FileReactionView';
import { ImageReaction } from '../models/reactions/ImageReaction';
import { ImageReactionView } from './ImageReactionView';
import { AudioReaction } from '../models/reactions/AudioReaction';
import { AudioReactionView } from './AudioReactionView';
import { VideoReaction } from '../models/reactions/VideoReaction';
import { VideoReactionView } from './VideoReactionView';
import { LocationReaction } from '../models/reactions/LocationReaction';
import { LocationReactionView } from './LocationReactionView';
import { SysIfReaction } from '@/app/scripts/models/reactions/SysIfReaction';
import { SysIfReactionView } from '@/app/scripts/components/SysIfReactionView';

export class ReactionViewFactory {
    static build(reaction: Reaction, props: Record<string, any> = {}) {
        switch (reaction.type) {
            case TextReaction.type:
                return <TextReactionNode reaction={reaction as TextReaction} {...props}/>;
            case JumpToReaction.type:
                return <JumpToReactionNode reaction={reaction as JumpToReaction} {...props}/>;
            case ButtonsReaction.type:
                return <ButtonsReactionView reaction={reaction as ButtonsReaction} {...props}/>;
            case SnippetReaction.type:
                return <SnippetReactionView reaction={reaction as SnippetReaction} {...props}/>;
            case SysIfReaction.type:
                return <SysIfReactionView reaction={reaction as SysIfReaction} {...props}/>
            case RepeatReaction.type:
                return <RepeatReactionView reaction={reaction as RepeatReaction} {...props}/>;
            case ResetReaction.type:
                return <ResetReactionView reaction={reaction as ResetReaction} {...props}/>;
            case TerminateReaction.type:
                return <TerminateReactionView reaction={reaction as TerminateReaction} {...props}/>;
            case FileReaction.type:
                return <FileReactionView reaction={reaction as FileReaction} {...props}/>;
            case ImageReaction.type:
                return <ImageReactionView reaction={reaction as ImageReaction} {...props}/>;
            case AudioReaction.type:
                return <AudioReactionView reaction={reaction as AudioReaction} {...props}/>;
            case VideoReaction.type:
                return <VideoReactionView reaction={reaction as VideoReaction} {...props}/>;
            case LocationReaction.type:
                return <LocationReactionView reaction={reaction as LocationReaction} {...props}/>;
            default:
                throw new Error('Unknown type of reaction, implement it in ReactionViewFactory')
        }
    }
}
