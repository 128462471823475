import React from 'react';
import { action, computed, IReactionDisposer, makeObservable, observable, reaction } from 'mobx';
import { observer } from 'mobx-react';
import cns from 'classnames';
import cn from './Branch.module.scss';
import { Reaction } from '../models/reactions/Reaction';
import { State } from '../models/State';
import { StateOrReaction } from './StateOrReaction';
import { DropZone } from './DropZone';
import { triggerTypes } from '../models/triggers/TriggerTypes';
import { reactionTypes } from '../models/reactions/ReactionTypes';
import { DropItem } from '../models/DropItem';
import { DropFactory } from '../factories/DropFactory';
import { SnippetReaction } from '../models/reactions/SnippetReaction';
import { withTranslation, WithTranslation } from 'react-i18next';
import { SysIfReaction } from '@/app/scripts/models/reactions/SysIfReaction';

type BranchesProps = {
    reaction: SnippetReaction | SysIfReaction;
    isSysIfReaction?: boolean;
} & WithTranslation;

enum Next {
    next, fail
}

@observer
export class SnippetReactionBranchesCmp extends React.Component<BranchesProps> {
    @observable activeBranch: Next = Next.next;

    branchesReaction?: IReactionDisposer;
    private lastActive?: Next;

    constructor(props: BranchesProps) {
        super(props);
        makeObservable(this);
    }

    componentDidMount(): void {
        this.branchesReaction = reaction(
            () => this.props.reaction.fail, () => {
                if (!this.props.reaction.fail) {
                    this.activeBranch = Next.next;
                }
            });
    }

    @computed get next(): State | Reaction | null {
        if (this.activeBranch === Next.next) {
            return this.props.reaction.next;
        } else {
            return this.props.reaction.nextFail;
        }
    }

    @action.bound
    private onNextActiveBranch = (drop: DropItem) => {
        const next = DropFactory.build(drop, this.props.reaction, this.activeBranch === Next.next);

        if (this.activeBranch === Next.next) {
            this.props.reaction.insertNext(next);
        } else {
            if (State.is(next)) {
                const state = next as State;
                if (state.triggers.length) {
                    state.triggers[0].next = this.props.reaction.nextFail;
                }
            }
            this.props.reaction.insertFailNext(next);
        }
    }

    buildOnClick = (next: Next) => () => {
        if (this.activeBranch !== next) {
            this.lastActive = this.activeBranch;
            this.activeBranch = next;
        }
    };

    render() {
        return <>
            <div className={cn.branches}>
                {
                    this.props.reaction.fail && <>
                        <button
                            className={cns(cn.branch, {
                                [cn.active]: this.activeBranch === Next.next,
                                [cn.deactivate]: this.lastActive === Next.next,
                            })}
                            onClick={this.buildOnClick(Next.next)}
                            key={'Success'}>
                            {this.props.t(this.props.isSysIfReaction ? 'flows.completed' : 'flows.success')}
                        </button>
                        <button
                            className={cns(cn.branch, {
                                [cn.active]: this.activeBranch === Next.fail,
                                [cn.deactivate]: this.lastActive === Next.fail,
                            })}
                            onClick={this.buildOnClick(Next.fail)}
                            key={'Fail'}>
                            {this.props.t(this.props.isSysIfReaction ? 'flows.not_completed' :'flows.fail')}
                        </button>
                    </>
                }
            </div>
            <DropZone accept={[...triggerTypes, ...reactionTypes]} onDrop={this.onNextActiveBranch}/>
            {
                (this.next) && <StateOrReaction next={this.next} key={this.next.name}/>
            }
        </>;
    }
}

export const SnippetReactionBranches = withTranslation()(SnippetReactionBranchesCmp);
