import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, Route, Routes } from 'react-router';
import { inject, observer } from 'mobx-react';
import { Page } from '@/common/components/page/Page';
import { TestChat } from '@/app/chat/components/TestChat';
import { ZeroScreen } from '@/common/components/zero-screen/ZeroScreen';
import { Loader } from '@/common/components/Loader';
import { ScriptComponent } from './Script';
import { IntentStore } from '../../intents/intent.store';
import { EntityStore } from '../../entities/entity.store';
import { ScriptsStore } from '../stores/scripts.store';
import { SideMenuRight } from '@/common/side-menu-right/SideMenuRight';
import { ScriptsMenu } from '../scripts-menu';
import cn from '@/app/scripts/components/Script.module.scss';
import { UserStore } from '@/core/stores/user.store';
import localforage from 'localforage';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom/dist';

type ScriptPateComponentProps1 = {
    intentStore?: IntentStore;
    entityStore?: EntityStore;
    scriptsStore?: ScriptsStore;
    user?: UserStore;
}
export const ScriptPage: FC<ScriptPateComponentProps1> = inject('intentStore', 'entityStore', 'scriptsStore', 'user')(observer(({
    intentStore, entityStore, scriptsStore, user
                                                                                                                                 }) => {
    const [isLoaded, setIsLoaded] = useState(false);
    const {t} = useTranslation();
    const navigate = useNavigate();
    const params = useParams();

    useEffect(() => {
        Promise.all([intentStore.load(),
            entityStore.load(),
            scriptsStore.load()]).then(async () => {
                setIsLoaded(true);
                const flowID = await localforage.getItem('currentFlowId');
                const script = scriptsStore.scripts.find(script => script.id === +flowID);
                if (script) {
                    navigate(`/app/${params.projectId}/flows/${script.id}`);
                }
        });
    }, []);

    if (!user.permissions.isViewScripts) {
        return <Navigate to={`/app/${user!.currentChoosedProjectId}/project-settings`}/>;
    }

    return <>
        <SideMenuRight>
            <ScriptsMenu scriptsStore={scriptsStore} intentStore={intentStore}/>
        </SideMenuRight>
        {isLoaded ? (
            <Routes>
                <Route path={'/'} element={
                    <Page rightBar={<TestChat/>}>
                        <ZeroScreen
                            title={t('flows.create_new_flow_title')}
                            subtitle={
                                <>
                                    {t('zero_screens.subtitle')} <br/>
                                    {t('flows.create_new_flow_subtitle_suffix')}
                                </>
                            }
                        />
                    </Page>
                }>
                </Route>
                <Route path={':id'} element={<ScriptComponent/>}/>
            </Routes>
        ) : (
            <div className={cn.loader}>
                <Loader/>
            </div>
        )}
    </>
}));
