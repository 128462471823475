import { Reaction } from './Reaction';
import { action, makeObservable, observable } from 'mobx';
import { State } from '../State';
import { Node } from '../Node';

export type BaseRuleType = 'channel' | 'param';
export type BaseRuleNextRuleType = 'and' | 'or';
export type ChannelCondition = 'equals' | 'not_equals';
type BaseRule = {
    type?: BaseRuleType;
    conditionalOperator: BaseRuleNextRuleType;
};

export type ChannelRule = BaseRule & {
    channel?: string;
    condition?: ChannelCondition;
};

export type ParamsRuleConditionType = 'exists' | 'not_exists' | 'includes' | 'not_includes' | 'equals' | 'not_equals';

export type ParamsRuleValue = string | string[] | number[] | (string | number)[];

export type ParamsRule = BaseRule & {
    name?: string;
    condition?: ParamsRuleConditionType;
    value?: ParamsRuleValue;
};

export type SysIfRule = ChannelRule | ParamsRule;

export class SysIfReaction extends Reaction {
    static className = 'SysIfReaction';

    @observable fail: boolean = true;
    @observable nextFail: State | Reaction | null = null;
    @observable rules: SysIfRule[] = [{ type: undefined, conditionalOperator: 'and'}];

    constructor(id: string, name: string) {
        super(id, name);
        makeObservable(this);
    }


    @action.bound
    appendRule(rule: SysIfRule) {
        this.rules.push(rule);
    }

    @action.bound
    removeRule(toRemoveRule: SysIfRule) {
        const index = this.rules.findIndex((rule) => toRemoveRule === rule);

        if (index !== -1) {
            this.rules.splice(index, 1);
        }
    }

    insertFailNext(newNext: State | Reaction) {
        if (!this.nextFail) {
            this.nextFail = newNext;
        } else {
            newNext.parent = this.nextFail.parent;
            this.nextFail.parent = newNext;
            newNext.next = this.nextFail;
            this.nextFail = newNext;
        }
    }

    @action.bound remove (child: Node) {
        if (child === this.next) {
            super.remove(child);
        } else if (child === this.nextFail) {
            const nextChild = this.nextFail.next;
            if (nextChild) {
                nextChild.parent = this;
                this.nextFail = nextChild as (Reaction | State);
            } else {
                this.nextFail = null;
            }
        } else {
            return;
        }
    }
}
