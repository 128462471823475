import { State } from '@/app/scripts/models/State';
import { IntentTrigger } from '@/app/scripts/models/triggers/IntentTrigger';
import { SnippetReaction } from '@/app/scripts/models/reactions/SnippetReaction';
import { TextReaction } from '@/app/scripts/models/reactions/TextReactions';
import { JumpToReaction } from '@/app/scripts/models/reactions/JumpToReaction';
import { EntityTrigger } from '@/app/scripts/models/triggers/EntityTrigger';
import { Flow, NodeTypes, Tree } from './types';
import {
    BaseUrlNode,
    BlueNode, ImageNode, BlueLinkNode, JumpToNode, SnippetNode, StateNode, WhiteNode, SysIfNode
} from '@/app/scripts/components/FlowDiagram/CustomNodes';
import { t } from 'i18next';
import { TerminateReaction } from '@/app/scripts/models/reactions/TerminateReaction';
import { AllowOutTrigger } from '@/app/scripts/models/triggers/AllowOutTrigger';
import { FallbackTrigger } from '@/app/scripts/models/triggers/FallbackTrigger';
import { StartTrigger } from '@/app/scripts/models/triggers/StartTrigger';
import { StickerTrigger } from '@/app/scripts/models/triggers/StickerTrigger';
import { ImageTrigger } from '@/app/scripts/models/triggers/ImageTrigger';
import { AudioTrigger } from '@/app/scripts/models/triggers/AudioTrigger';
import { VideoTrigger } from '@/app/scripts/models/triggers/VideoTrigger';
import { FileTrigger } from '@/app/scripts/models/triggers/FileTrigger';
import { LocationTrigger } from '@/app/scripts/models/triggers/LocationTrigger';
import { ImageReaction } from '@/app/scripts/models/reactions/ImageReaction';
import { VideoReaction } from '@/app/scripts/models/reactions/VideoReaction';
import { AudioReaction } from '@/app/scripts/models/reactions/AudioReaction';
import { LocationReaction } from '@/app/scripts/models/reactions/LocationReaction';
import { ButtonsReaction } from '@/app/scripts/models/reactions/ButtonsReaction';
import { FileReaction } from '@/app/scripts/models/reactions/FileReaction';
import { RepeatReaction } from '@/app/scripts/models/reactions/RepeatReaction';
import { ResetReaction } from '@/app/scripts/models/reactions/ResetReaction';
import Elk from 'elkjs';
import { MarkerType } from 'reactflow';
import { SysIfReaction } from '@/app/scripts/models/reactions/SysIfReaction';

export const isTriggers = (node: Tree): node is State => {
    return (node as State).triggers?.length > 0;
}

export const isIntentTrigger = (node: Tree): node is IntentTrigger => {
    return node.type === IntentTrigger.className;
}

export const isSnippets = (node: Tree): node is SnippetReaction => {
    return node.type === SnippetReaction.className;
}

export const isSystemIf = (node: Tree): node is SysIfReaction => {
    return node.type === SysIfReaction.className;
}
export const isTextReaction = (node: Tree): node is TextReaction => {
    return 'texts' in node;
}

export const isJumpToReaction = (node: Tree): node is JumpToReaction => {
    return node.type === JumpToReaction.className;
}

export const isImageReaction = (node: Tree): node is ImageReaction => {
    return node.type === ImageReaction.className;
}

export const isVideoReaction = (node: Tree): node is VideoReaction => {
    return node.type === VideoReaction.className;
}

export const isAudioReaction = (node: Tree): node is AudioReaction => {
    return node.type === AudioReaction.className;
}

export const isLocationReaction = (node: Tree): node is LocationReaction => {
    return node.type === LocationReaction.className;
}

export const isButtonsReaction = (node: Tree): node is ButtonsReaction => {
    return node.type === ButtonsReaction.className;
}

export const isFileReaction = (node: Tree): node is FileReaction => {
    return node.type === FileReaction.className;
}

export const isRepeatReaction = (node: Tree): node is RepeatReaction => {
    return node.type === RepeatReaction.className;
}

export const isResetReaction = (node: Tree): node is ResetReaction => {
    return node.type === ResetReaction.className;
}

export const isTerminateReaction = (node: Tree): node is TerminateReaction => {
    return node.type === TerminateReaction.className;
}

export const isEntityTrigger = (node: Tree): node is EntityTrigger => {
    return node.type === EntityTrigger.className;
}

export const isState = (node: Tree): node is State => {
    return node.type === State.className;
}


export const isStartTrigger = (node: Tree): node is StartTrigger => {
    return node.type === StartTrigger.className;
}

export const isFallbackTrigger = (node: Tree): node is FallbackTrigger => {
    return node.type === FallbackTrigger.className;
}

export const isAllowOutTrigger = (node: Tree): node is AllowOutTrigger => {
    return node.type === AllowOutTrigger.className;
}

export const isStickerTrigger = (node: Tree): node is StickerTrigger => {
    return node.type === StickerTrigger.className;
}

export const isImageTrigger = (node: Tree): node is ImageTrigger => {
    return node.type === ImageTrigger.className;
}

export const isAudioTrigger = (node: Tree): node is AudioTrigger => {
    return node.type === AudioTrigger.className;
}

export const isVideoTrigger = (node: Tree): node is VideoTrigger => {
    return node.type === VideoTrigger.className;
}

export const isFileTrigger = (node: Tree): node is FileTrigger => {
    return node.type === FileTrigger.className;
}

export const isLocationTrigger = (node: Tree): node is LocationTrigger => {
    return node.type === LocationTrigger.className;
}

export const getNodeId = (node: Tree) => {
    if ('id' in node) {
        return node.id;
    } else if (node.type && node.parent && 'id' in node.parent) {
        return node.type + '-' + node.parent.id;
    }
}

export const typeOfNode = (node: Tree) => {
    if (node.type.includes('Reaction')) {
        return 'reaction';
    } else if (node.type.includes('Trigger')) {
        return 'trigger';
    } else if (node.type.includes('State')) {
        return 'state';
    }
}

export const nodeTypes = {
    [NodeTypes.BLUE]: BlueNode,
    [NodeTypes.SNIPPET]: SnippetNode,
    [NodeTypes.JUMPTO]: JumpToNode,
    [NodeTypes.IMAGE]: ImageNode,
    [NodeTypes.URL]: BaseUrlNode,
    [NodeTypes.WHITE]: WhiteNode,
    [NodeTypes.STATE]: StateNode,
    [NodeTypes.LINK_LIST]: BlueLinkNode,
    [NodeTypes.SYSIF]: SysIfNode
};

export const getNodeType = (node: Tree) => {
    if (isStartTrigger(node) ||
        isFallbackTrigger(node) ||
        isAllowOutTrigger(node) ||
        isStickerTrigger(node) ||
        isImageTrigger(node) ||
        isAudioTrigger(node) ||
        isVideoTrigger(node) ||
        isFileTrigger(node) ||
        isLocationTrigger(node)
    ) {
        return NodeTypes.BLUE;
    }
    if (isSnippets(node)) {
        return NodeTypes.SNIPPET;
    } else if(isSystemIf(node)) {
        return NodeTypes.SYSIF;
    } else if (isJumpToReaction(node)) {
        return NodeTypes.JUMPTO;
    } else if (isImageReaction(node)) {
        return NodeTypes.IMAGE;
    } else if (isVideoReaction(node) || isAudioReaction(node) || isFileReaction(node)) {
        return NodeTypes.URL;
    } if (isState(node)) {
        return NodeTypes.STATE;
    } if (isIntentTrigger(node) || isEntityTrigger(node)) {
        return NodeTypes.LINK_LIST;
    } else {
        return NodeTypes.WHITE;
    }
}

export const getLabel = (node: Tree) => {
    if (isSnippets(node.parent)) {
        if (node.parent.nextFail === node) {
            return t('flows.fail')
        } else if (node.parent.nextFail) {
            return t('flows.success')
        }
    }
}

const elkOptions = {
    'elk.algorithm': 'layered',
    'elk.direction': 'DOWN',
    'elk.layered.spacing': '500',
    'elk.layered.mergeEdges': 'true',
    'elk.spacing': '500',
    'elk.spacing.individual': '50',
    'elk.edgeRouting': 'POLYLINE',
    'elk.spacing.nodeNode': '50',
    'elk.spacing.edgeNode': '50',
    'elk.layered.spacing.nodeNodeBetweenLayers': '50',
    'elk.alignment': 'CENTER'
};

export function autoLayouting(flow: Flow, options = elkOptions) {
    const elk = new Elk({
        defaultLayoutOptions: options
    });

    return elk.layout(
        {
            id: 'root',
            children: flow.nodes,
            edges: flow.edges.map(edge => {
                return {
                    id: edge.id,
                    sources: [edge.source],
                    targets: [edge.target]
                }
            })
        },
        {layoutOptions: options,}
    ).then((newGraph) => {
        const updatedNodes = flow.nodes.map((el) => {
            const node = newGraph?.children?.find((n) => n.id === el.id);
            if (node?.x && node?.y && node?.width && node?.height) {
                el.position = {
                    x: node.x - node.width / 2 + Math.random() / 1000,
                    y: node.y - node.height / 2
                };
            }
            return el;
        });

        flow.edges.filter(edge => {
                return !!edge.data.jumpNext;
            })
            .filter(edge => {
                return flow.nodes.find(node => node.id === edge.data.jumpNext)
            })
            .forEach(edge => {
                flow.edges.push(
                    {
                        id: `${edge.target} ### ${edge.data.jumpNext}`,
                        source: edge.target,
                        target: edge.data.jumpNext,
                        animated: true,
                        markerEnd: {
                            type: MarkerType.ArrowClosed,
                        },
                    }
                )
            });

        return { edges: flow.edges, nodes: updatedNodes };
    });
}
