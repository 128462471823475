import React, { FC, useEffect } from 'react';
import { observer } from 'mobx-react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import { Channel } from '../../models/channel';
import { ChannelEditStore } from '@/app/connects/components/ConnectPageContent';
import { Button, Form, InputNumber, Space, Input, Switch } from 'antd';
import { getCurrentDomain } from '@/common/utils/get-current-domain';
import { useMaxLength, useRegExp, useRequired } from '@/common/utils/form-validators';
import { CopyOutlined } from '@ant-design/icons';

type EdnaProps = {
    channel: Channel;
    channelEditStore: ChannelEditStore;
    isManageChannels: boolean;
    onFinish: (values: any) => void;
    isRequest: boolean;
}


export const Edna: FC<EdnaProps> = observer(({
                                                                              channel,
                                                                              isManageChannels,
                                                                              channelEditStore,
                                                                              onFinish,
                                                                              isRequest
                                                                          }) => {
    const {t} = useTranslation();
    const [form] = Form.useForm();
    channelEditStore.setCurrentEditForm(form);
    const liaWebHook = `${getCurrentDomain()}/channels/edna?key=${channel.api_key}`;
    const onFinishForm = (values: any) => {
        onFinish(values);
    }

    useEffect(() => form.resetFields(), [channel.id]);

    const required = useRequired();
    const maxLength = useMaxLength(4096);
    const maxLength128 = useMaxLength(128);
    const startsWithQaIntentValidator = useRegExp(/^qa-.+|intent-.+/, 'qa- / intent-')

    const initialValues = {
        subdomain: channel.params.subdomain,
        edna_token: channel.params.edna_token,
        max_message_len: channel.params.max_message_len,
        long_text_intent: channel.params.long_text_intent,
        init_chat: channel.params.init_chat,
    };

    return <Form form={form} style={{maxWidth: '600px'}} layout="vertical" onFinish={onFinishForm}
                 initialValues={initialValues}>
        <Form.Item label={t('connect.edna.input_label_link')}>
            <Space.Compact
                style={{
                    width: '100%',
                }}
            >
                <Input value={liaWebHook} readOnly/>
                <CopyToClipboard text={liaWebHook} onCopy={() => alert(t('actions.copied'))}>
                    <Button type="default" icon={<CopyOutlined/>}/>
                </CopyToClipboard>
            </Space.Compact>
        </Form.Item>

        <Form.Item label={t('connect.edna.init_chat')} name="init_chat">
            <Switch disabled={!isManageChannels || isRequest}/>
        </Form.Item>

        <Form.Item rules={[required, maxLength]} name="subdomain"
                   label={t('connect.edna.input_label_subdomain')}>
            <Input disabled={!isManageChannels || isRequest}/>
        </Form.Item>

        <Form.Item rules={[required, maxLength]} name="edna_token" label={t('connect.edna.input_label_token')}>
            <Input disabled={!isManageChannels || isRequest}/>
        </Form.Item>

        <Form.Item rules={[required]} name="max_message_len"
                   label={t('connect.edna.max_message_len')}>
            <InputNumber min={2} disabled={!isManageChannels || isRequest}/>
        </Form.Item>

        <Form.Item rules={[required, maxLength128, startsWithQaIntentValidator]} name="long_text_intent"
                   label={t('connect.edna.long_text_intent')}>
            <Input disabled={!isManageChannels || isRequest}/>
        </Form.Item>
    </Form>
});
