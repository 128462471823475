import React, { useCallback, useEffect, useState, useLayoutEffect, FC } from 'react';
import { useBlocker, useParams } from 'react-router-dom';

import { inject, observer, Provider } from 'mobx-react';

import { Button } from 'antd';
import { ApartmentOutlined } from '@ant-design/icons';

import { Page } from '@/common/components/page/Page';
import { ScriptPageStore } from '@/app/scripts/stores/script-page.store';

import { ScriptActionMenu } from './ScriptActionMenu';
import { RightMenu } from '@/app/components/right-menu/RightMenu';
import { TestChat } from '@/app/chat/components/TestChat';
import { EditableText } from '@/common/components/EditableText';
import { Flow } from './Flow';
import { IntentsSelector } from './IntentsSelector';
import nodeCN from './Node.module.scss';
import paramsCN from './Params.module.scss';
import stateNodesCN from './FallbackTriggerView.module.scss';
import triggerCN from './BaseTriggerView.module.scss';
import { ProjectStore } from '@/core/stores/project.store';
import { ParamsFactory } from './ParamsFactory';
import { Trigger } from '../models/triggers/Trigger';
import { Reaction } from '../models/reactions/Reaction';
import { Loader } from '@/common/components/Loader';
import { LabelsStore } from '../stores/labels.store';
import { ScriptsStore } from '../stores/scripts.store';
import cn from './Script.module.scss';
import { UserStore } from '@/core/stores/user.store';
import { IntentStore } from '@/app/intents/intent.store';
import { SnippetStore } from '@/app/snippets/snippet.store';
import { EntityStore } from '@/app/entities/entity.store';
import { CopyId } from '@/app/scripts/components/CopyId/CopyId.component';
import { ConfirmLeave } from '@/common/components/ConfirmLeave/ConfirmLeave';
import localforage from 'localforage';

type ScriptComponentProps = {
    projectStore?: ProjectStore;
    scriptsStore?: ScriptsStore;
    user?: UserStore;
    intentStore?: IntentStore;
    snippetStore?: SnippetStore;
    entityStore?: EntityStore;
}

const nodeClasses = [nodeCN.node, paramsCN.editor, stateNodesCN.node, paramsCN.remove, triggerCN.node, nodeCN.selector, nodeCN.option];
const hasElementParentWithClasses = (e: Element) => {
    let target = e;

    while (target.parentNode) {
        if (nodeClasses.some(cls => target.classList.contains(cls))) {
            return true;
        }
        target = target.parentNode as Element;
    }

    return false;
}

export const ScriptComponent: FC<ScriptComponentProps> = inject('projectStore', 'scriptsStore', 'user')(observer(({
                                                                                                                                                                      projectStore,
                                                                                                                                                                      scriptsStore,
                                                                                                                                                                      user,
                                                                                                                                                                  }) => {
    const [scriptPageStore] = useState(() => new ScriptPageStore(scriptsStore, user));
    const [labelsStore] = useState(() => new LabelsStore(scriptPageStore, projectStore, scriptsStore));
    const [isLoading, setIsLoading] = useState(true);
    const { id: flowID } = useParams();

    const onDocumentClick = useCallback((e: MouseEvent) => {
        if (!hasElementParentWithClasses(e.target as Element)) {
            scriptPageStore.select();
        }
    }, [scriptPageStore]);

    const fetchPageData = async () => {
        if (flowID !== 'new') {
            localforage.setItem('currentFlowId', flowID);
        }
        await scriptPageStore.fetchScript(flowID !== 'new' ? { id: +flowID } : undefined);
        await labelsStore.reload();
    };

    const renderRightBar = () => {
        const selectedNode = scriptPageStore.selectedNode && ParamsFactory.build(scriptPageStore.selectedNode as (Trigger | Reaction));
        return selectedNode ||
            <RightMenu content={user.permissions.isEditScripts && <IntentsSelector/>} chat={<TestChat/>} contentMaxHeight={'470px'} />;
    };

    const renderActions = () => user.permissions.isEditScripts && <ScriptActionMenu store={scriptPageStore}/>;
    const renderLoader = () => <div className={cn.loader}><Loader/></div>;

    useEffect(() => {
        setIsLoading(true);
        fetchPageData().finally(() => setIsLoading(false));
    }, [flowID]);

    useLayoutEffect(() => {
        document.addEventListener('click', onDocumentClick);
        return () => {
            document.removeEventListener('click', onDocumentClick);
        };
    }, [onDocumentClick]);
    const blocker = useBlocker(
        ({ currentLocation, nextLocation }) =>
            scriptPageStore.isChanged() && currentLocation.pathname !== nextLocation.pathname
    );

    if (isLoading) return renderLoader();


    return (
        <Provider scriptPageStore={scriptPageStore} labels={labelsStore}>
            <Page actionMenu={renderActions()} rightBar={renderRightBar()}>

                {blocker.state === 'blocked' ? (
                    <ConfirmLeave onConfirm={blocker.proceed} onCancel={blocker.reset}/>
                ) : null}

                <div className={cn.header}>
                    <div>
                        <EditableText className={cn.editableTitle} text={scriptPageStore.script.name}
                                        onEdit={scriptPageStore.updateName}
                                        editable={user.permissions.isEditScripts}/>
                        <CopyId align={false} id={scriptPageStore.script.data.state?.id}/>
                    </div>
                    <Button target={'_blank'} rel="noreferrer" href={`/diagram/${projectStore.choosenProject?.id}/flows/${flowID}`} type="default" icon={<ApartmentOutlined />} size={'large'} />
                </div>
                <Flow flow={scriptPageStore.script.data}/>
            </Page>
        </Provider>
    );
}));

