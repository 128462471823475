import './polyfills';

import React from 'react';
import { Provider } from 'mobx-react';

import './vendor';
import './app/i18n/i18n';
import * as serviceWorker from './serviceWorker.js';
import { Root } from './Root';
import { rootStore } from './core/stores/root.store';
import { bootstrapService } from './core/bootstrap.service';

import 'bootstrap/dist/css/bootstrap.min.css';
import './common/scss/app.scss'
import './index.css';

import './localstorage.config';
import { createRoot } from 'react-dom/client';
import { createBrowserRouter } from 'react-router-dom';
import { RouterProvider } from 'react-router';

if (process.env.NODE_ENV === 'production') {
    import('./metricas/mixpanel.metrica').then(({initMixpanelMetrica}) => initMixpanelMetrica());
    import('./metricas/yandex.metrica').then(({initYandexMetrica}) => initYandexMetrica());
    import('./metricas/sentry.metrica').then(({initSentryMetrica}) => initSentryMetrica());
}

const root = createRoot(document.getElementById('root'));
const router = createBrowserRouter([
    {path: '*', element: <Root/>},
]);
root.render(
    <Provider projectStore={rootStore.projectStore} user={rootStore.userStore} root={rootStore}
              bootstrap={bootstrapService}>
        <div className={'theme-light'}>
            <RouterProvider router={router}/>;
        </div>

        <div id="popper-tooltip-portal"></div>
    </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
