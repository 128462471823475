import React from 'react';
import { Reaction } from '../models/reactions/Reaction';
import { State as StateModel } from '../models/State';
import { State } from './State';
import { ReactionViewFactory } from './ReactionViewFactory';
import { observer } from 'mobx-react';

interface StateOrReactionProps {
    next: StateModel | Reaction;
}
@observer
export class StateOrReaction extends React.Component<StateOrReactionProps> {
    render() {
        return StateModel.is(this.props.next) ?
            <State state={this.props.next as StateModel}/> : ReactionViewFactory.build(this.props.next as Reaction)
    }
}
