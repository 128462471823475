import React from 'react';
import cn from './State.module.scss';
import { inject, observer } from 'mobx-react';
import { State as StateModel } from '../models/State';
import CloseIcon from 'mdi-react/CloseIcon';
import { Trigger } from '../models/triggers/Trigger';
import cns from 'classnames';
import { action, computed, IReactionDisposer, makeObservable, observable, reaction } from 'mobx';
import { StateNodeItemWrapper } from './StateNodeItemWrapper';
import { FallbackTrigger } from '../models/triggers/FallbackTrigger';
import { AllowOutTrigger } from '../models/triggers/AllowOutTrigger';
import { TriggerViewFactory } from './TriggerViewFactory';
import { DropZone } from './DropZone';
import { triggerTypes } from '../models/triggers/TriggerTypes';
import { reactionTypes } from '../models/reactions/ReactionTypes';
import { DropItem } from '../models/DropItem';
import { TriggersFactory } from '../factories/TriggersFactory';
import { EntityStore } from '../../entities/entity.store';
import { IntentStore } from '../../intents/intent.store';
import { FallbackTriggerView } from './FallbackTriggerView';
import { StateOrReaction } from './StateOrReaction';
import { DropFactory } from '../factories/DropFactory';
import { IntentTrigger } from '../models/triggers/IntentTrigger';
import { EntityTrigger } from '../models/triggers/EntityTrigger';
import { IntentTriggerBranches } from './IntentTriggerBranches';
import { EntityTriggerBranches } from './EntityTriggerBranches';
import { UserStore } from '@/core/stores/user.store';
import { withTranslation, WithTranslation } from 'react-i18next';

type StateComponentProps = {
    state: StateModel;
    entityStore?: EntityStore;
    intentStore?: IntentStore;
    user?: UserStore;
} & WithTranslation;

@inject('entityStore', 'intentStore', 'user')
@observer
class StateComp extends React.Component<StateComponentProps> {
    // @observable activeTrigger?: Trigger;
    @observable activeTriggerIndex?: number = 0; // -1 -fallback

    @computed get activeTrigger(): Trigger | null {
        return this.activeTriggerIndex === -1
            ? this.props.state.fallback : this.props.state.triggers[this.activeTriggerIndex];
    }

    private reactionDisposers: Array<IReactionDisposer> = [];

    constructor(props: StateComponentProps) {
        super(props);
        makeObservable(this);
        this.onChangeTriggers();
    }

    componentDidMount(): void {
        // when delete active trigger, set active to the first trigger or null
        this.reactionDisposers.push(reaction(() => this.props.state.triggers.length, this.onChangeTriggers));
        this.reactionDisposers.push(reaction(() => this.props.state.fallback, this.onChangeTriggers));
    }

    componentWillUnmount(): void {
        this.reactionDisposers.forEach(dispose => {
            dispose();
        });
    }

    @action.bound
    private onChangeTriggers = () => {
        if (this.activeTriggerIndex === -1 && this.activeTrigger) {
            return;
        }

        if (this.props.state.triggers.length === 0 && this.props.state.fallback) {
            return this.activeTriggerIndex = -1;
        }

        if (this.props.state.triggers.length - 1 < this.activeTriggerIndex) {
            this.activeTriggerIndex = 0;
        }
    }

    @computed get accept() {
        let accept = triggerTypes;

        if (this.props.state.triggers.some(t => FallbackTrigger.is(t))) {
            accept = accept.filter(t => t !== FallbackTrigger.type);
        }

        if (this.props.state.triggers.some(t => t.type === AllowOutTrigger.type)) {
            accept = accept.filter(t => t !== AllowOutTrigger.type);
        }

        return accept;
    }

    render() {
        const {state} = this.props;
        const [, value] = state.name.split(' ');
        return <>
            <div className={cn.state}>
                {
                    this.canDelete &&
                    <div className={cn.delete} onClick={this.removeSelf}>
                        <CloseIcon size={18} color={'#999'}/>
                    </div>
                }
                <div className={cn.stateName}>{this.props.t('flows.state')} {value}</div>
                <div className={cn.children}>
                    <DropZone accept={this.accept} onDrop={this.onAddTrigger}
                              classes={{drop: cn.drop, over: cn.dropOver, hide: cn.hide}}/>
                    {
                        this.props.state.triggers.map(this.renderTrigger)
                    }
                    {
                        this.props.state.fallback && this.renderFallback(this.props.state.fallback)
                    }
                </div>
            </div>
            {
                this.activeTrigger && this.showDropZone &&
                <div style={{marginTop: 15}}>
                    <DropZone accept={[...triggerTypes, ...reactionTypes]} onDrop={this.onNextActiveTrigger}/>
                </div>
            }
            {
                this.renderActiveTriggerNext()
            }
        </>;
    }

    @computed
    private get canDelete() {
        return this.props.user!.permissions.isEditScripts;
    }

    @computed
    private get showDropZone() {
        return this.activeTrigger && !IntentTrigger.is(this.activeTrigger) && !EntityTrigger.is(this.activeTrigger);
    }

    private renderActiveTriggerNext() {
        if (!this.activeTrigger) {
            return null;
        }

        const defaultNext = this.activeTrigger.next ? <React.Fragment key={this.activeTrigger.next.id}>
            <StateOrReaction next={this.activeTrigger.next}/>
        </React.Fragment> : null;

        switch (this.activeTrigger.type) {
            case IntentTrigger.type:
                return <IntentTriggerBranches trigger={this.activeTrigger as IntentTrigger}/>;
            case EntityTrigger.type:
                return <EntityTriggerBranches trigger={this.activeTrigger as EntityTrigger}/>;
            default:
                return defaultNext;
        }
    }

    @action.bound
    private removeSelf = () => {
        this.props.state.removeSelfWithNextTrigger(this.activeTrigger);
    }

    @action.bound
    private onNextActiveTrigger = (drop: DropItem) => {
        if (!this.activeTrigger) {
            return;
        }

        const next = DropFactory.build(drop, this.activeTrigger);
        this.activeTrigger.insertNext(next);
        this.activeTrigger.parent.insertNext(next);

        next.parent = this.activeTrigger;
        // if (!this.activeTrigger.next) {
        //     this.activeTrigger.next = next;
        // } else {
        //     // const prevNext = this.activeTrigger.next
        //     // this.activeTrigger.next = next;
        //     // next.next = prevNext;
        //     // prevNext.parent = next;
        // }
        //
//      const flow = FlowIterator.getHead(next.parent) as Flow;
    }

    private onClickTrigger = (index: number) => {
        return () => {
            this.activeTriggerIndex = index;
        }
    };

    private renderFallback = (trigger: FallbackTrigger) => {
        return <div onClick={this.onClickTrigger(-1)} className={cns(cn.trigger, {
            [cn.fallback]: this.props.state.triggers.length > 0,
            [cn.inactive]: this.activeTrigger !== trigger,

        })}>
            <FallbackTriggerView trigger={trigger}/>
        </div>
    };

    @action.bound
    private onMoveTrigger = (from: number, to: number) => {
        this.props.state.moveTrigger(from, to);
        this.activeTriggerIndex = to;
    }

    private renderTrigger = (trigger: Trigger, index: number) => {
        // @ts-ignore
        return <StateNodeItemWrapper
            id={`${index}`}
            index={index}
            key={String(index  + trigger.type)}
            move={this.onMoveTrigger}
            className={cns(cn.trigger, {
                [cn.fallback]: AllowOutTrigger.is(trigger),
                [cn.inactive]: this.activeTrigger !== trigger,
                [cn.active]: this.activeTrigger === trigger,
            })}
            onClick={this.onClickTrigger(index)}
            draggable={this.props.user!.permissions.isEditScripts}
		>
            {TriggerViewFactory.build(trigger)}
        </StateNodeItemWrapper>
    };


    @action.bound
    private onAddTrigger = (drop: DropItem) => {
        const trigger = TriggersFactory.build({
            parent: this.props.state, type: drop.type, params: {allow: 'out'}, branches: []
        });

        this.props.state.addTrigger(trigger);
        this.activeTriggerIndex = FallbackTrigger.is(trigger) ? -1 : 0;
    }
}

export const State = withTranslation()(StateComp);
