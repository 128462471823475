import { Project } from '@/common/models/project';
import { API } from '@/common/api';

type SearchResult = {
    type: 'qa' | 'flow' | 'intent';
    id: number;
    name: string;
    is_active: boolean;
    text: string[];
};
class SearchAPI extends API {
    static search(project: Project, term: string, type_entity: string[], exactSearch: boolean) {
        return SearchAPI.get<SearchResult[]>(`/projects/${project.id}/intent-qa-search`, { search: term, type_entity, exact_search: exactSearch })
    }
}

export class SearchItemsStore {
    async search(project: Project, term: string, type_entity: string[], exactSearch: boolean) {
       return await SearchAPI.search(project, term, type_entity, exactSearch);
    }
}
