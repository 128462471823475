import React, { FC, useEffect, useState } from 'react';
import { Page } from '@/common/components/page/Page';
import { ActionsMenu } from '@/common/components/actions-menu/ActionsMenu';
import cn from './IntentPage.module.scss';
import TooltipText from 'mdi-react/TooltipTextIcon';
import { EditableText } from '@/common/components/EditableText';
import { inject, Observer, observer, Provider } from 'mobx-react';
import cns from 'classnames';
import { IntentExample } from './IntentExample';
import { EntityStore } from '../../entities/entity.store';
import { IntentsPageStore } from '../intents-page.store';
import { SaveButton } from '@/common/components/save-button/SaveButton';
import { IntentStore } from '../intent.store';

import { TestChat } from '../../chat/components/TestChat';
import { EntityEditor } from './EntityEditor';
import { Loader } from '@/common/components/Loader';
import { ChatStore } from '../../chat/chat.store';
import { RightMenu } from '../../components/right-menu/RightMenu';
import { useTranslation } from 'react-i18next';
import { Tracker } from '@/core/analytics/tracker';
import { Permission, UserStore } from '@/core/stores/user.store';
import { PermissionsView } from '@/app/permissions/Permissions';
import { ConfirmDelete } from '@/common/components/ConfirmDelete/ConfirmDelete.component';
import { Button, Space, Switch, Input, Form } from 'antd';
import { CopyOutlined, DeleteOutlined } from '@ant-design/icons';
import { useBlocker, useLocation, useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom/dist';
import { ConfirmLeave } from '@/common/components/ConfirmLeave/ConfirmLeave';
import { cloneDeep } from 'lodash-es';
import localforage from 'localforage';

const {TextArea} = Input;


type IntentsProps = {
    entityStore?: EntityStore;
    intentStore?: IntentStore;
    chatStore?: ChatStore;
    user?: UserStore;
};
export const Intents: FC<IntentsProps> = inject('entityStore', 'intentStore', 'user')(observer(({
                                                                                                    entityStore,
                                                                                                    intentStore,
                                                                                                    user
                                                                                                }) => {
    const [store] = useState(() => new IntentsPageStore(entityStore, intentStore));
    const {t} = useTranslation();
    const navigate = useNavigate();
    const params = useParams();
    const location = useLocation();

    const blocker = useBlocker(
        ({currentLocation, nextLocation}) =>
            store.isChanged && currentLocation.pathname !== nextLocation.pathname
    );

    useEffect(() => {
        store.setIntent(params.id ? +params.id : undefined);
        if (params.id) {
            localforage.setItem('currentIntentId', params.id);
        }
    }, [location]);
    const onPasteSynonym = (e: React.ClipboardEvent) => {
        const text = e.clipboardData.getData('Text');
        const list: string[] = text.split(/\r?\n/).filter(item => item);
        if (list.length > 1) {

            store.onExampleMultiAdd(list);
            setTimeout(() => store.exampleText = '');
        }
    };

    const duplicate = async () => {
        const cloneIntent = cloneDeep(store.intent);
        delete cloneIntent.id;
        cloneIntent.name = `${cloneIntent.name} (clone)`;
        intentStore.saveIntent(cloneIntent);
        Tracker.trackEvent('Edit', {Object: 'intent', Type: 'duplicate'});
    };

    const toggleArchive = () => {
        if (!store.intent.id) {
            return;
        }

        const isActive = store.intent.is_active;

        intentStore.updateIntent({...store.intent, is_active: !isActive});
        store.intent.is_active = !isActive;
        Tracker.trackEvent('Edit', {Object: 'intent', Type: 'archive'});
    };

    const deleteIntent = async () => {
        Tracker.trackEvent('Edit', {Object: 'intent', Type: 'delete'});
        await intentStore.removeIntent(store.intent);

        navigate(`/app/${params.projectId}/intent`, {replace: true});
    };

    const saveIntent = async () => {
        const validEntity = store.entities.every(entity => !!entity.name);
        if (!validEntity) {
            store.errorEntityMessage = 'Fill entity names';
            return;
        }

        Tracker.trackEvent('Save', {Object: 'intent', Name: store.intent.name});

        store.errorEntityMessage = '';
        const intent = await store.save();
        navigate(`/app/${params.projectId}/intent/${intent.id.toString()}`, {replace: true});
    };


    return <Provider store={store}>
        {
            store.intent
                ? <Page
                    actionMenu={
                        <>
                            {
                                user.permissions.isEditIntents && <ActionsMenu
                                    right={
                                        <Space size={[12, 0]} wrap>
                                            {
                                                store.intent.id &&
                                                <>
                                                    <Space size={[4, 0]} wrap>
                                                        {t(store.intent.is_active ? 'Включен' : 'Выключен')}
                                                        <Switch checked={store.intent.is_active} size={'small'}
                                                                onChange={toggleArchive}/>
                                                    </Space>

                                                    <Button title="Дублировать" onClick={duplicate}
                                                            icon={<CopyOutlined/>}/>

                                                    <ConfirmDelete title={t('actions.delete_intent')}
                                                                   question={t('actions.delete_element', {name: store.intent.name})}
                                                                   onConfirm={() => deleteIntent()}>
                                                        <Button title="Удалить" icon={<DeleteOutlined/>}/>
                                                    </ConfirmDelete>

                                                    {/*<button className="btn btn-link link"*/}
                                                    {/*        onClick={this.toggleArchive}>*/}
                                                    {/*    {this.props.t(this.store.intent.is_active ? 'actions.archive' : 'actions.unarchived')}*/}
                                                    {/*</button>*/}
                                                </>
                                            }
                                            <Observer>
                                                {() =>
                                                    <SaveButton onClick={saveIntent} state={store.saveState}
                                                                titlesByState={store.titlesByState}
                                                                disabled={!store.canSave}/>
                                                }
                                            </Observer>
                                        </Space>
                                    }/>
                            }
                        </>
                    }
                    rightBar={<RightMenu
                        content={store.hasEntities ? <div className={cn.entityList}>
                            <h5 className={cn.entityHeader}>{t('entities.entities')}</h5>
                            <div className={cn.editor}>
                                <div>
                                    <Observer>
                                        {() => <>{store.entities.map(entity => <EntityEditor entity={entity}
                                                                                             key={entity.id}/>)}</>}
                                    </Observer>
                                </div>
                                <span className={cn.errorMessage}>{store.errorEntityMessage}</span>
                            </div>
                        </div> : <div className={cn.tip}>
                            <TooltipText size={48}/>
                            <p>
                                {t('intents.mark_entities')}<br/>
                                {t('intents.from_text')}:<br/>
                                <b>{t('intents.hint_intent_text')} <span
                                    className={cn.selected}>{t('intents.hint_intent_date')}</span></b>
                            </p>
                        </div>}
                        chat={<TestChat/>}
                        contentMaxHeight={'324px'}
                    />}>

                    {blocker.state === 'blocked' ? (
                        <ConfirmLeave onConfirm={blocker.proceed} onCancel={blocker.reset}/>
                    ) : null}

                    <div className={cn.header}>
                        <EditableText text={store.intent.name} onEdit={(name) => {
                            store.isTitleEdited = true;
                            store.changeName(name);
                        }} editable={user.permissions.isEditIntents} className={cn.title}/>
                        <div className={cn.headerButtons}>
                            <button className={cn.entitiesViewToggle}
                                    onClick={store.toggleView}>{t('intents.entities_view')}</button>
                        </div>
                    </div>
                    <PermissionsView permission={Permission.EDIT_INTENTS}>
                        <Form>
                            <div className="form__form-group">
                <span className={cns('form__form-group-label', cn.formLabel)}>
                    {t('intents.what_is_say')}
                </span>
                                <div className="form__form-group-field">
                                    <div className="form__form-group-input-wrap">
                                        <TextArea placeholder={t('intents.new_phrase')}
                                                  onPaste={onPasteSynonym}
                                                  autoSize={{minRows: 1, maxRows: 5}}
                                                  value={store.exampleText}
                                                  onKeyDown={e => {
                                                      if (e.key === 'Enter' && !e.ctrlKey && !e.shiftKey) {
                                                          e.preventDefault();
                                                          store.onExampleAdd();
                                                      }
                                                  }}
                                                  onChange={e => store.exampleText = e.target.value}/>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    </PermissionsView>
                    <div className={cn.examplesList}>
                        {store.intent.intent_examples
                            .map((example, i) => (
                                    <IntentExample editedExample={store.editedExample} editEnd={store.editEnd}
                                                   editable={user.permissions.isEditIntents} example={example} key={i}
                                                   onRemove={store.onRemoveExample} onTryEdit={store.onTryEdit}/>
                                )
                            )}
                    </div>
                </Page>
                : <Loader/>
        }
    </Provider>
}));
