import React from 'react';
import {
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Modal, Popover,
    PopoverBody, PopoverHeader,
    UncontrolledDropdown
} from 'reactstrap';
import classnames from 'classnames';
import { makeObservable, observable, reaction } from 'mobx';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from '@/common/utils/withRouter';
import ChevronDownIcon from 'mdi-react/ChevronDownIcon';
import CrossIcon from 'mdi-react/CloseIcon';

import cn from './HeaderProject.module.scss';
import { HeaderCreateProject } from './HeaderCreateProject';
import { Project } from '@/common/models/project';
import { inject, observer, Provider } from 'mobx-react';
import { TemplateProjectsStore } from './store/template-projects.store';
import { AppStore } from '../../stores/app.store';
import { ProjectStore } from '@/core/stores/project.store';
import { Permission, UserStore } from '@/core/stores/user.store';
import { ErrorsStore } from '@/core/stores/errors.store';
import { CustomAlert } from '@/common/components/alert/CustomAlert';
import { AuthAPI } from '@/auth/auth.api';
import { Tracker } from '@/core/analytics/tracker';
import { ProfileStore } from '@/app/profile-settings/profile.store';
import { ConnectStore } from '../../connects/connect.store';
import { Company } from '@/app/profile-settings/models/company';
import { PermissionsView } from '@/app/permissions/Permissions';
import { Link } from 'react-router-dom/dist';
import { changePageTitle } from '@/common/utils/change-page-title';
import { SearchItemsComponent } from '@/app/components/header/SearchItems.component';

interface HeaderProjectProps extends RouteComponentProps<{ projectId: string }>, WithTranslation {
    user?: UserStore;
    profileStore?: ProfileStore;
    appStore?: AppStore;
    projectStore?: ProjectStore;
    errorsStore?: ErrorsStore;
    connectStore?: ConnectStore;
}

@inject('appStore', 'projectStore', 'user', 'errorsStore', 'connectStore', 'profileStore')
@observer
export class HeaderProjectComponent extends React.Component<HeaderProjectProps> {
    templateProjectsStore = new TemplateProjectsStore();
    @observable isOpenProjects = false;
    @observable currentChoosedProject: Project = new Project(NaN, '');
    @observable publishProcess = false;
    @observable showPublishError = false;
    @observable linkToWidget: string = '';
    @observable isOpenNotActivatedEmailAlert = false;
    @observable isLoadingActivated = false;
    @observable isDisabledPopoverVisible = false;

    constructor(props: HeaderProjectProps) {
        super(props);
        makeObservable(this);
        this.props.connectStore!.loadChannels();
        reaction(() => this.props.connectStore!.channels.length, () => {
            const widgetChannels = this.props.connectStore!.channels.filter(channel => channel.is_active && channel.channel_type === 'widget');
            if (widgetChannels) {
                this.linkToWidget = `${window.location.origin}/widget/demo/${this.props.connectStore!.channels[0] && widgetChannels[0].api_key}`;
            }
        })
    }

    UNSAFE_componentWillMount() {
        this.updateChoosedProject();
        this.fetchOrganizations();
    }

    toggle = () => {
        this.isOpenProjects = !this.isOpenProjects;
    };

    async fetchOrganizations() {
        await this.props.profileStore!.getCompany();
    }

    onSelectProject = (project: Project) => async () => {
        try {
            Tracker.trackEvent('SelectProject', { From: this.props.user!.currentChoosedProjectId, to: project.id });
            await this.props.user!.chooseProject(project.id);
            this.updateChoosedProject(project);
            this.props.history.push(`/app/${project.id}/flows`);
            this.props.appStore!.clearApp();
            this.props.connectStore!.loadChannels();
            changePageTitle(`Лия - ${project.name}`);
        } catch (e) {
            console.error('Error with selected project', e);
        }
    };

    updateChoosedProject(project?: Project) {
        this.currentChoosedProject = project || this.props.projectStore!.choosenProject;
    }

    onActivatedClick = async () => {
        this.isLoadingActivated = true;
        await AuthAPI.sendActivationMail(this.props.user!.userInfo!.email);
        this.isLoadingActivated = false;
        this.isOpenNotActivatedEmailAlert = !this.isOpenNotActivatedEmailAlert;
    };

    toggleActivatedEmailModal = () => {
        this.isOpenNotActivatedEmailAlert = !this.isOpenNotActivatedEmailAlert;
    };

    onShareBtnHover = () => {
        this.isDisabledPopoverVisible = true;
    }

    onShareBtnBlur = () => {
        this.isDisabledPopoverVisible = false;
    }

    onCreateProject = async (project: Project) => {
        await this.onSelectProject(project)();
    };

    renderProjectItem = (project: Project) => {
        return (
            <DropdownItem key={project.id} onClick={this.onSelectProject(project)}>
                {project.name}
            </DropdownItem>
        );
    }

    renderOrganization(org: Company, projects: Project[], isLast: boolean = false) {
        return (
            <>
                <DropdownItem disabled className={cn.headerProjectOrgName}>
                    {org && org.name ? org.name : this.props.t('project_header.yours')}
                    {` ${this.props.t('project_header.organization')}`}
                </DropdownItem>
                {projects.map(this.renderProjectItem)}
                {!isLast && <DropdownItem divider/>}
            </>
        );
    }

    renderDisabledSharePopover = () => (
        <Popover isOpen={this.isDisabledPopoverVisible} placement="right-start" target="sharePopover">
            <PopoverHeader>Share link</PopoverHeader>
            <PopoverBody>
                <div>{this.props.t('project_header.disabled_share_msg')}</div>
                <Link
                    to={`/app/${this.props.params.projectId}/publish`}
                    onClick={this.onShareBtnBlur}
                >{this.props.t('project_header.disabled_share_link')}</Link>
            </PopoverBody>
        </Popover>
    );

    render() {
        // const { channels } = this.props.connectStore!;
        const { projects } = this.props.projectStore!;
        const { company } = this.props.profileStore!;
        const projectTitle = projects.length === 1 ? 'project_header.projects_one' : 'project_header.projects_many';
        // const isShareEnabled = channels && channels.length;

        return <div className={cn.headerProject}>
            <CustomAlert
                isOpen={this.isOpenNotActivatedEmailAlert}
                toggle={this.toggleActivatedEmailModal}
                onButtonClick={this.onActivatedClick}
                isLoadingActivated={this.isLoadingActivated}
                title={'project_header.verify'}
                subtitle={'project_header.verify_subtitle'}
                buttonText={'project_header.activation_email'}
            />
            <div className={cn.headerProjectHeader}>
                <UncontrolledDropdown className={cn.headerProjectUncontroller}>
                    <DropdownToggle
                        className={classnames(cn.headerProjectDropdown, 'icon icon--right')}
                        tag="div"
                        color="primary">
                        <p className={cn.headerProjectTitle}>
                            {this.props.projectStore!.choosenProject.name}
                            <ChevronDownIcon className={cn.headerProjectTitleIcon}/>
                        </p>
                    </DropdownToggle>
                    <DropdownMenu className={classnames(cn.headerProjectDropdownWrapper, 'dropdown__menu')}>
                        <DropdownItem
                            disabled
                            className={'dropdown-item--title'}
                        >
                            {this.props.t(projectTitle)}
                            {projects.length > 1 && ` (${projects.length})`}
                        </DropdownItem>
                        <DropdownItem divider/>
                        <div className={cn.headerProjectProjectsWrapper}>
                            {this.renderOrganization(company, projects, true)}
                        </div>
                        <DropdownItem divider/>
                        <DropdownItem onClick={this.toggle} className={'dropdown-item--control'}>
                            <Modal
                                className={cn.createProjectModalHost}
                                contentClassName={cn.createProjectModal}
                                backdropClassName={cn.createProjectModalBackdrop}
                                isOpen={this.isOpenProjects}
                                toggle={this.toggle}
                            >
                                <Provider templateProjectsStore={this.templateProjectsStore}>
                                    <HeaderCreateProject onCreateProjectAction={this.onCreateProject}
                                                         closeModal={this.toggle}/>
                                </Provider>
                            </Modal>
                            <PermissionsView permission={Permission.MANAGE_PROJECTS}>
                                <div className={cn.headerAddProjectButton}>
                                    {this.props.t('project_header.add_project')}
                                </div>
                            </PermissionsView>
                        </DropdownItem>
                    </DropdownMenu>
                </UncontrolledDropdown>
            </div>

            <SearchItemsComponent/>
            {/*<div className={cn.headerPublishWrapper} onMouseEnter={this.onShareBtnHover} onMouseLeave={this.onShareBtnBlur}>*/}
            {/*    <Button size="sm"*/}
            {/*            disabled={isShareEnabled ? !channels[0] : true}*/}
            {/*            outline*/}
            {/*            color="success"*/}
            {/*            id="sharePopover"*/}
            {/*            className={cn.headerShare}*/}
            {/*    >*/}
            {/*        <ShareVariantIcon/>*/}
            {/*    </Button>*/}
            {/*    {!isShareEnabled && this.renderDisabledSharePopover()}*/}
            {/*    <UncontrolledPopover trigger="legacy" placement="right-start" target="sharePopover">*/}
            {/*        <PopoverHeader>Share link</PopoverHeader>*/}
            {/*        <PopoverBody>*/}
            {/*            <div>*/}
            {/*                <div className="form__form-group-field">*/}
            {/*                    <CopyToClipboard text={this.linkToWidget}>*/}
            {/*                        <div className={classnames(cn.headerShareIcon, 'form__form-group-icon')}>*/}
            {/*                            <ContentCopyIcon/>*/}
            {/*                        </div>*/}
            {/*                    </CopyToClipboard>*/}
            {/*                    <input*/}
            {/*                        className={cn.headerShareInput}*/}
            {/*                        name="leftIcon"*/}
            {/*                        type="text"*/}
            {/*                        value={this.linkToWidget}*/}
            {/*                    />*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        </PopoverBody>*/}
            {/*    </UncontrolledPopover>*/}
            {/*</div>*/}
            {this.props.errorsStore!.headerFixedError ?
                <div className={cn.errorPublish}>
                    {this.props.errorsStore!.headerFixedError}
                    <div className={cn.errorIcon} onClick={() => this.props.errorsStore!.removeHeaderError()}><CrossIcon
                        size={14}/></div>
                </div> : ''}
        </div>
    }
}

export const HeaderProject = withTranslation()(withRouter(HeaderProjectComponent));
