import { TextReactionParamsEditor } from './TextReactionParamsEditor';
import * as React from 'react';
import { IntentTriggerParamsEditor } from './IntentTriggerParamsEditor';
import { EntityTriggerParamsEditor } from './EntityTriggerParamsEditor';
import { AllowTriggerParamsEditor } from './AllowTriggerParamsEditor';
import { JumpToReactionParamsEditor } from './JumpToReactionParamsEditor';
import { ButtonsReactionParamsEditor } from './ButtonsReactionParamsEditor';
import { SnippetReactionParamsEditor } from './SnippetReactionParamsEditor';
import { FileReactionParamsEditor } from './FileReactionParamsEditor';
import { LocationReactionParamsEditor } from './LocationReactionParamsEditor';
import { Trigger } from '../models/triggers/Trigger';
import { Reaction } from '../models/reactions/Reaction';
import { TextReaction } from '../models/reactions/TextReactions';
import { JumpToReaction } from '../models/reactions/JumpToReaction';
import { ButtonsReaction } from '../models/reactions/ButtonsReaction';
import { SnippetReaction } from '../models/reactions/SnippetReaction';
import { FileReaction } from '../models/reactions/FileReaction';
import { AudioReaction } from '../models/reactions/AudioReaction';
import { ImageReaction } from '../models/reactions/ImageReaction';
import { VideoReaction } from '../models/reactions/VideoReaction';
import { FileLikeReaction } from '../models/reactions/FileLikeReaction';
import { LocationReaction } from '../models/reactions/LocationReaction';
import { FallbackTrigger } from '../models/triggers/FallbackTrigger';
import { IntentTrigger } from '../models/triggers/IntentTrigger';
import { EntityTrigger } from '../models/triggers/EntityTrigger';
import { AllowOutTrigger } from '../models/triggers/AllowOutTrigger';
import { DefaultReactionParamsEditor } from '@/app/scripts/components/DefaultReactionParamsEditor';
import { SysIfParamsEditor } from '@/app/scripts/components/SysIfParamsEditor';
import { SysIfReaction } from '@/app/scripts/models/reactions/SysIfReaction';

export class ParamsFactory {
    static build(node: Trigger | Reaction) {
        if (Reaction.is(node)) {
            return this.buildReaction(node as Reaction);
        } else if (Trigger.is(node)) {
            return this.buildTrigger(node as Trigger);
        }
    }

    static buildReaction(reaction: Reaction) {
        switch (reaction.type) {
            case TextReaction.type:
                return <TextReactionParamsEditor reaction={reaction as TextReaction}/>;
            case JumpToReaction.type:
                return <JumpToReactionParamsEditor reaction={reaction as JumpToReaction}/>;
            case ButtonsReaction.type:
                return <ButtonsReactionParamsEditor reaction={reaction as ButtonsReaction}/>;
            case SnippetReaction.type:
                return <SnippetReactionParamsEditor reaction={reaction as SnippetReaction}/>;
            case SysIfReaction.type:
                return <SysIfParamsEditor reaction={reaction as SysIfReaction}/>;
            case FileReaction.type:
            case AudioReaction.type:
            case ImageReaction.type:
            case VideoReaction.type:
                return <FileReactionParamsEditor reaction={reaction as FileLikeReaction}/>;
            case LocationReaction.type:
                return <LocationReactionParamsEditor reaction={reaction as LocationReaction}/>;
            default:
                // Это не едитор, просто чтобы отображать id реакции
                return <DefaultReactionParamsEditor reaction={reaction as Reaction}/>
        }
    }

    private static buildTrigger(trigger: Trigger) {
        switch (trigger.type) {
            case FallbackTrigger.type:
                return <div/>;
            case IntentTrigger.type:
                return <IntentTriggerParamsEditor trigger={trigger as IntentTrigger}/>;
            case EntityTrigger.type:
                return <EntityTriggerParamsEditor trigger={trigger as EntityTrigger}/>;
            case AllowOutTrigger.type:
                return <AllowTriggerParamsEditor trigger={trigger as AllowOutTrigger}/>;
        }
    }
}
