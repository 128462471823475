import React from 'react';
import cn from './IntentExample.module.scss';
import { IntentExample as IntentExampleModel, IntentExamplePart } from '../models/Intent';
import { observer } from 'mobx-react';
import { ExampleEntity } from './ExampleEntity';

import { action } from 'mobx';
import { CloseOutlined, EditOutlined } from '@ant-design/icons';
import { Input, Modal } from 'antd';
import cls from 'classnames';

const {TextArea} = Input;

interface ExampleTextProps {
    part: IntentExamplePart;
    onSelection: ({start, end}: { start: number, end: number }) => void;
}

class ExampleText extends React.PureComponent<ExampleTextProps> {
    el = React.createRef<HTMLSpanElement>();

    constructor(props: ExampleTextProps) {
        super(props);
        this.mouseUpHandler = this.mouseUpHandler.bind(this);
        document.addEventListener('mouseup', this.mouseUpHandler)
    }

    mouseUpHandler() {
        const selection = window.getSelection();
        if (selection.rangeCount !== 1) {
            return;
        }
        const range = window.getSelection().getRangeAt(0);
        if (range.startContainer.parentNode !== this.el.current) {
            return;
        }

        if (range.startOffset !== range.endOffset) {
            const end = range.startContainer === range.endContainer ? range.endOffset : this.props.part.text.length;
            this.props.onSelection({start: range.startOffset, end});
            selection.empty();
        }
    }

    componentWillUnmount() {
        document.removeEventListener('mouseup', this.mouseUpHandler);
    }

    render() {
        return <span className={cn.part} ref={this.el} style={{userSelect: 'text'}}
                     dangerouslySetInnerHTML={{__html: this.props.part.text}}></span>
    }
}

interface IntentExampleProps {
    onRemove: (example: IntentExampleModel) => void;
    onTryEdit: (example: IntentExampleModel) => void;
    example: IntentExampleModel;
    editable: boolean;
    editedExample?: IntentExampleModel;
    editEnd: () => void;
}

@observer
export class IntentExample extends React.Component<IntentExampleProps> {
    onSelection(part: IntentExamplePart, {start, end}: { start: number, end: number }) {
        if (!part.text.slice(start, end).trim()) { // not select spaces and tabs
            return;
        }
        const index = this.props.example.parts.findIndex(p => part === p);
        const newParts: Array<IntentExamplePart> = [
            {text: part.text.slice(start, end), entity_id: 0}
        ];
        if (start !== 0) {
            newParts.unshift({text: part.text.slice(0, start)})
        }
        if (end !== part.text.length) {
            newParts.push({text: part.text.slice(end, part.text.length)})
        }

        // hack, for not rerender
        setTimeout(
            action(() => {
                this.props.example.parts.splice(index, 1, ...newParts);
            })
        );
    }

    onRemove(part: IntentExamplePart) {
        const parts = this.props.example.parts;
        const index = parts.findIndex(p => p === part);
        if (index === 0) {
            const right = parts[index + 1];
            if (right && right.entity_id == null) {
                const newPart = {text: part.text + right.text};
                this.props.example.parts.splice(0, 2, newPart)
            } else {
                part.entity_id = null;
            }
        } else if (index === this.props.example.parts.length - 1) {
            const left = parts[index - 1];
            if (left && left.entity_id == null) {
                const newPart = {text: left.text + part.text};
                this.props.example.parts.splice(index - 1, 2, newPart)
            } else {
                part.entity_id = null;
            }
        } else {
            const [left, right] = [parts[index - 1], parts[index + 1]];
            let removeIndex = index - 1;
            let removeCount = 3;
            const newPart: IntentExamplePart = {text: '', entity_id: null};
            if (left.entity_id) {
                removeIndex = index;
                removeCount = removeCount - 1;
            } else {
                newPart.text += left.text;
            }

            newPart.text += part.text;

            if (right.entity_id) {
                removeCount = removeCount - 1;
            } else {
                newPart.text += right.text;
            }

            parts.splice(removeIndex, removeCount, newPart);
        }
    }

    @action.bound
    onRemoveExample = () => {
        this.props.onRemove(this.props.example);
    }

    @action.bound
    tryEdit = () => {
        this.props.onTryEdit(this.props.example);
    }

    @action.bound
    startEdit = () => {
        if (this.props.editedExample === this.props.example) {
            return;
        }

        const isEditable = this.props.example.parts.length <= 1 && this.props.example.parts?.every((part) => !part.entity_id);
        if (isEditable) {
            this.tryEdit();
        } else {
            Modal.confirm({
                title: 'Перед редактированием удалите все сущности',
                footer: (_, {OkBtn}) => (
                    <>
                        <OkBtn/>
                    </>
                ),
            });
        }
    }

    render() {
        return <div onDoubleClick={this.startEdit} className={cls(this.props.editedExample !== this.props.example && cn.example)}>
            {this.props.editedExample !== this.props.example && <div className={cn.partWrapper}>
                {
                    this.props.editable ? this.props.example.parts.map((part, i) => {
                        return part.entity_id != null
                            ? <ExampleEntity part={part} onRemove={() => this.onRemove(part)} key={i}/>
                            : <ExampleText part={part} onSelection={(args) => this.onSelection(part, args)} key={i}/>
                    }) : this.props.example.parts.map((part, i) => {
                        return <span key={i}>{part.text}</span>
                    })
                }
            </div>
            }
            {
                this.props.editedExample === this.props.example && <TextArea onChange={e => {
                    this.props.example.parts[0].text = e.target.value
                }}
                                                                             onKeyDown={e => {
                                                                                 if (e.key === 'Enter' && !e.ctrlKey && !e.shiftKey) {
                                                                                     e.preventDefault();
                                                                                     this.props.editEnd();
                                                                                 }
                                                                             }} autoSize={{minRows: 1, maxRows: 5}}
                                                                             value={this.props.example.parts[0].text}/>
            }
            {this.props.editable && <>
                <div className={cn.actionsContainer}>
                    <CloseOutlined onClick={this.onRemoveExample} size={14} className={cn.remove}/>
                    <EditOutlined onClick={this.startEdit} size={14} className={cn.remove}/>
                </div>
                {/*<CloseOutlined />*/}
            </>}
        </div>;
    }
}
