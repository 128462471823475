import React from 'react';
import { action, computed, makeObservable, observable, reaction } from 'mobx';
import { WithTranslation, withTranslation } from 'react-i18next';


import { ActionsMenu } from '@/common/components/actions-menu/ActionsMenu';
import { SaveButton, SaveButtonState } from '@/common/components/save-button/SaveButton';

import { ScriptPageStore } from '../stores/script-page.store';
import { generatePath } from 'react-router';
import { ScriptDTO } from '../dtos/script.dto';
import { observer } from 'mobx-react';
import { RouteComponentProps, withRouter } from '@/common/utils/withRouter';
import { ConfirmDelete } from '@/common/components/ConfirmDelete/ConfirmDelete.component';
import { Button, Space, Switch } from 'antd';
import { CopyOutlined, DeleteOutlined } from '@ant-design/icons';

import { FadeAnimation } from '@/app/scripts/components/ScriptActionMenu.styled';

type ScriptActionMenuProps = RouteComponentProps<{ projectId: string, id: string }> & WithTranslation & {
    store: ScriptPageStore;
}

@observer
export class ScriptActionMenuComp extends React.Component<ScriptActionMenuProps> {
    // @observable autoSaveInfo: { isAutoSave: boolean; autoSaveDate: string; } = undefined;
    @computed get store(): ScriptPageStore {
        return this.props.store;
    }

    @observable currentSaveState: SaveButtonState = SaveButtonState.default;
    @observable fader = 1;
    private _titlesByState: Record<SaveButtonState, string> = {
        [SaveButtonState.default]: 'flows.save',
        [SaveButtonState.process]: 'actions.saving',
        [SaveButtonState.saved]: 'actions.saved',
        [SaveButtonState.error]: 'actions.error'
    };

    constructor(props: ScriptActionMenuProps) {
        super(props);
        makeObservable(this);

        reaction(() => this.store.autoSaveInfo?.autoSaveDate, () => {
            const min = 0.4;
            const max = 0.41;

            this.fader = min + Math.random() * (max - min);
        });
    }

    @action.bound
    private goAfterSave = (script: ScriptDTO) => {
        const nowOnNewFlowPage = !this.props.match.params.id || this.props.match.params.id == 'new';
        if (nowOnNewFlowPage && script.id) {
            const {params: {projectId}} = this.props.match;
            const replacePath = generatePath('/app/:projectId/flows/:id', {projectId, id: script.id.toString()});
            this.props.history!.replace(replacePath);
        }
    }

    @action.bound
    save = async () => {
        this.currentSaveState = SaveButtonState.process;
        return this.store.save()
            .then(this.goAfterSave)
            .then(() => {
                this.currentSaveState = SaveButtonState.saved;
            })
            .catch((e) => {
                console.error('Error with flow save', e);
                this.currentSaveState = SaveButtonState.error;
            })
            .then(() => {
                setTimeout(() => this.currentSaveState = SaveButtonState.default, 3000);
            })
    }

    @action.bound
    delete = async () => {
        await this.store.delete();
        const {params: {projectId}} = this.props.match;
        this.props.history!.replace(`/app/${projectId}/flows`);
    }

    @action.bound
    clone = async () => {
        try {
            const clonedScript = await this.store.clone();
            const {params: {projectId}} = this.props.match;
            const replacePath = generatePath('/app/:projectId/flows/:id', {projectId, id: clonedScript.id.toString()});
            this.props.history!.replace(replacePath)
        } catch(e) {
            console.error('EEEE', e);
        }
    }

    onChange = (val: boolean) => {
        this.store.autoSaveToggle(val);
    }

    render() {
        return <ActionsMenu
            left={
                <Space size={[4, 0]} wrap>
                    <Switch size={'small'} checked={this.store.autoSaveInfo?.isAutoSave} onChange={this.onChange}/>
                    <FadeAnimation fader={this.fader} type={'secondary'} style={{fontSize: '10px'}}>{this.store.autoSaveInfo?.isAutoSave && this.store.autoSaveInfo?.autoSaveDate ? `${this.store.autoSaveInfo?.autoSaveDate} Автосохранено` : 'Автосохранение'}</FadeAnimation>
                </Space>
            }
            right={
                <Space size={[12, 0]} wrap>
                    {
                        this.store.script.id &&
                        <>
                            <Space size={[4, 0]} wrap>
                                {this.props.t(this.store.script.isActive ? 'Включен' : 'Выключен')}
                                <Switch checked={this.store.script.isActive} size={'small'} onChange={this.store.toggleArchive} />
                            </Space>
                            <ConfirmDelete title={this.props.t('actions.delete_script')}
                                           question={this.props.t('actions.delete_element', {name: this.store.script?.name})}
                                           onConfirm={() => this.delete()}>
                                <Button title="Удалить" icon={<DeleteOutlined/>}/>
                            </ConfirmDelete>
                            <Button onClick={this.clone} title="Дублировать" icon={<CopyOutlined/>}/>
                        </>
                    }
                    <SaveButton onClick={this.save} state={this.currentSaveState}
                                titlesByState={this._titlesByState}/>
                </Space>
            }/>
    }
}

export const ScriptActionMenu = withTranslation()(withRouter(ScriptActionMenuComp));
