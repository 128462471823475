import React, { useState } from 'react';
import { LoginDTO } from '../../models/login.dto';
import cn from './LoginForm.module.scss';
import cns from 'classnames';
import { Link } from 'react-router-dom/dist';
import { useTranslation } from 'react-i18next';
import { Input, Button, Form } from 'antd';
import { useEmail, useRequired } from '@/common/utils/form-validators';

type LoginFormProps = {
    onLogin: (data: LoginDTO) => void;
    errors: Record<string, string[]>;
    clearErrors: () => void;
    isLoading: boolean;
}

export const LoginForm = ({onLogin, errors, clearErrors, isLoading}: LoginFormProps) => {
    const [showPassword] = useState(false);
    const {t} = useTranslation();
    const [form] = Form.useForm();
    const required = useRequired();
    const emailRule = useEmail();

    return (
        <Form className="form" form={form} onValuesChange={() => clearErrors()} onFinish={(values) => {
            const {email, password, totp_token} = values;
            onLogin({email, password, totp_token})
        }}>
            <Form.Item rules={[required, emailRule]} name="email" className="form__form-group-input-wrap">
                <Input size={'large'}
                       placeholder={t('auth.email')}
                />

            </Form.Item>
            <Form.Item rules={[required]} name="password" className="form__form-group-input-wrap">
                <Input
                    size={'large'}
                    type={showPassword ? 'text' : 'password'}
                    placeholder={t('auth.password')}
                />
            </Form.Item>

            <Form.Item rules={[required]} name="totp_token" className="form__form-group-input-wrap">
                <Input
                    size={'large'}
                    placeholder={t('auth.totp_token')}
                />
            </Form.Item>
            <div className={cn.errorWrapper}>
                {
                    errors['totp_token'] && errors['totp_token'].map((error, key) =>
                        <span className="form__form-group-error" key={key}>{t('auth.totp_token_invalid')}</span>)
                }
                {
                    errors['non_field_errors'] &&
                    <span className="form__form-group-error">{t('auth.non_field_errors')}</span>
                }
                {
                    errors['email'] && errors['email'].map((error, key) =>
                        <span className="form__form-group-error" key={key}>{t('auth.email_invalid')}</span>)
                }
                {
                    errors['conflict'] && errors['conflict'].map((error, key) =>
                        <span className="form__form-group-error" key={key}>{error}</span>)
                }
                {
                    errors['password'] && errors['password'].map((error, key) =>
                        <span className="form__form-group-error" key={key}>{t('auth.empty_field')}</span>)
                }
            </div>
            <div className="account__forgot-password">
                <Link to={'/auth/reset'}>{t('auth.forgot')}</Link>
            </div>
            <div className={cns('account__btns', cn.footer)}>
                    {/*<Button style={{flex: 1}} size={'large'}*/}
                    {/*        onClick={() => onSignUp({email: form.getFieldValue('email')})}>{t('auth.create_account')}*/}
                    {/*</Button>*/}
                    <Button loading={isLoading} htmlType="submit" style={{flex: 1}} type={'primary'} size={'large'}>{t('auth.sign_in')}
                    </Button>
            </div>
        </Form>
    );
};

