import React, { FC, useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router';
import { inject, observer } from 'mobx-react';
import cn from './SnippetPage.module.scss';
import { Loader } from '@/common/components/Loader';
import { SnippetStore } from '../snippet.store';
import { Snippet } from './snippet-edit/Snippet';
import { SnippetZero } from './snippet-zero/SnippetZero';
import { SideMenuRight } from '@/common/side-menu-right/SideMenuRight';
import { SnippetMenu } from './snippets-menu';
import { UserStore } from '@/core/stores/user.store';
import { useNavigate } from 'react-router-dom';
import localforage from 'localforage';
import { useParams } from 'react-router-dom/dist';

type SnippetPageProps = {
    snippetStore?: SnippetStore;
    user?: UserStore;
}

export const SnippetPage: FC<SnippetPageProps> = inject('snippetStore', 'user')(observer(({snippetStore, user
                                                                                                  }) => {
    const navigate = useNavigate();
    const params = useParams();

    useEffect(() => {
            localforage.getItem('currentSnippetId').then((currentSnippetId) => {
                const snippet = snippetStore.snippets.find(snippet => snippet.id === +currentSnippetId);
                if (snippet) {
                    navigate(`/app/${params.projectId}/snippets/${snippet.id}`);
                }
            })
    }, []);

    if (!user.permissions.isViewFacts) {
        return <Navigate to={'/'}/>;
    }

    return <>
        <SideMenuRight>
            <SnippetMenu snippetStore={snippetStore}/>
        </SideMenuRight>
        <div className={cn.wrapper}>
            {
                snippetStore.isLoaded
                    ? <Routes>
                        <Route path={'/'} element={<SnippetZero />}>
                        </Route>
                        <Route path={':id'} element={<Snippet />}/>
                        {/*<Route element={<Navigate to={'/'}/>}/>*/}
                    </Routes>
                    : <div className={cn.loader}><Loader/></div>
            }
        </div>
    </>
}))

