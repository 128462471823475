import React from 'react';
import { ReactionNode, ReactionNodeProps } from './ReactionNode';
import { SnippetStore } from '../../snippets/snippet.store';
import { inject, observer } from 'mobx-react';
import { ScriptPageStore } from '../stores/script-page.store';
import { SnippetReactionBranches } from './SnippetReactionBranches';
import { RouteComponentProps, withRouter } from '@/common/utils/withRouter';
import { WithTranslation, withTranslation } from 'react-i18next';
import { SysIfReaction, SysIfRule } from '@/app/scripts/models/reactions/SysIfReaction';

interface SnippetReactionViewProps extends ReactionNodeProps<SysIfReaction>, RouteComponentProps<{ projectId: string }>, WithTranslation {
    snippetStore?: SnippetStore;
    scriptPageStore?: ScriptPageStore;
}

@inject( 'scriptPageStore')
@observer
export class SysIfReactionViewComp extends ReactionNode<SysIfReaction, SnippetReactionViewProps> {
    constructor(props: SnippetReactionViewProps) {
        super(props);

        this.alwaysShowBranches = true;
        // @ts-ignore
        this.onDrop = undefined;
    }

    onClick(): void {
        this.props.scriptPageStore!.select(this.reaction);
    }

    onDelete(): void {
        this.props.reaction.removeSelf();

        this.props.scriptPageStore!.select();
    }

    renderNext() {
        return <SnippetReactionBranches isSysIfReaction={true} reaction={this.reaction}/>;
    }

    private get isEmptyCondition() {
        if (this.props.reaction.rules.length === 1) {
            const trigger = this.props.reaction.rules[0];
            const count = Object.keys(trigger).reduce((curr, prev) => {
                if (trigger[prev as keyof SysIfRule]) {
                    curr++;
                }
                return curr;
            }, 0);

            return count <= 1;
        }

        return false;
    }

    renderBody(): React.ReactNode {
        if (this.isEmptyCondition) {
            return 'Выберите правила';
        }

        return this.props.reaction.rules.reduce((currRule, prevRule, i) => {
            currRule.push(`Правило ${i + 1}`);
            if (this.props.reaction.rules.length > 1 && i !== this.props.reaction.rules.length - 1) {
                currRule.push(this.props.t(`flows.${prevRule.conditionalOperator}`));
            }
            return currRule;
        }, []).join(' ');
    }
}

export const SysIfReactionView = withRouter(withTranslation()(SysIfReactionViewComp));
