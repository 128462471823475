import React from 'react';
import cn from './IntentsSelector.module.scss';
import { IntentSelectorItem, IntentSelectorItemProps } from './IntentSelectorItem';
import { WithTranslation, withTranslation } from 'react-i18next';
import { IntentTrigger } from '../models/triggers/IntentTrigger';
import { EntityTrigger } from '../models/triggers/EntityTrigger';
import { AllowOutTrigger } from '../models/triggers/AllowOutTrigger';
import { FallbackTrigger } from '../models/triggers/FallbackTrigger';
import { StartTrigger } from '../models/triggers/StartTrigger';
import { StickerTrigger } from '../models/triggers/StickerTrigger';
import { ImageTrigger } from '../models/triggers/ImageTrigger';
import { AudioTrigger } from '../models/triggers/AudioTrigger';
import { VideoTrigger } from '../models/triggers/VideoTrigger';
import { FileTrigger } from '../models/triggers/FileTrigger';
import { LocationTrigger } from '../models/triggers/LocationTrigger';
import { TextReaction } from '../models/reactions/TextReactions';
import { ImageReaction } from '../models/reactions/ImageReaction';
import { VideoReaction } from '../models/reactions/VideoReaction';
import { JumpToReaction } from '../models/reactions/JumpToReaction';
import { AudioReaction } from '../models/reactions/AudioReaction';
import { LocationReaction } from '../models/reactions/LocationReaction';
import { ButtonsReaction } from '../models/reactions/ButtonsReaction';
import { FileReaction } from '../models/reactions/FileReaction';
import { RepeatReaction } from '../models/reactions/RepeatReaction';
import { ResetReaction } from '../models/reactions/ResetReaction';
import { TerminateReaction } from '../models/reactions/TerminateReaction';
import { SnippetReaction } from '../models/reactions/SnippetReaction';
import { SysIfReaction } from '@/app/scripts/models/reactions/SysIfReaction';

export const groups: {
    triggers: Array<IntentSelectorItemProps>,
    reactions: Array<IntentSelectorItemProps>
} = {
    triggers: [
        {name: 'flows.triggers.intent', type: IntentTrigger.type},
        {name: 'flows.triggers.entity', type: EntityTrigger.type},
        {name: 'flows.triggers.allow_out', type: AllowOutTrigger.type},
        {name: 'flows.triggers.fallback', type: FallbackTrigger.type},
        {name: 'flows.triggers.start', type: StartTrigger.type},
        {name: 'flows.triggers.sticker', type: StickerTrigger.type},
        {name: 'flows.triggers.image', type: ImageTrigger.type},
        {name: 'flows.triggers.audio', type: AudioTrigger.type},
        {name: 'flows.triggers.video', type: VideoTrigger.type},
        {name: 'flows.triggers.file', type: FileTrigger.type},
        {name: 'flows.triggers.location', type: LocationTrigger.type}
    ],
    reactions: [
        {name: 'flows.reactions.text', type: TextReaction.type},
        {name: 'flows.reactions.image', type: ImageReaction.type},
        {name: 'flows.reactions.video', type: VideoReaction.type},
        {name: 'flows.reactions.jump_to', type: JumpToReaction.type},
        {name: 'flows.reactions.audio', type: AudioReaction.type},
        {name: 'flows.reactions.location', type: LocationReaction.type},
        {name: 'flows.reactions.button', type: ButtonsReaction.type},
        {name: 'flows.reactions.file', type: FileReaction.type},
        {name: 'flows.reactions.repeat', type: RepeatReaction.type},
        {name: 'flows.reactions.reset', type: ResetReaction.type},
        {name: 'flows.reactions.terminate', type: TerminateReaction.type},
        {name: 'flows.reactions.snippet', type: SnippetReaction.type},
        {name: 'flows.reactions.sysif', type: SysIfReaction.type},
    ]
};

export class IntentsSelectorComp extends React.Component<WithTranslation> {
    render() {
        return <div className={cn.intents}>
            <div className={cn.group}>
                <div className={cn.groupTitle}>{this.props.t('flows.triggers.triggers')}</div>

                <div className={cn.groupContent}>
                    {
                        groups.triggers.map((trigger) => {
                            return <IntentSelectorItem {...trigger} key={trigger.type}/>
                        })
                    }
                </div>
            </div>
            <div className={cn.group}>
                <div className={cn.groupTitle}>{this.props.t('flows.reactions.reactions')}</div>
                {
                    groups.reactions.map((reaction) => {
                        return <IntentSelectorItem {...reaction} key={reaction.type}/>
                    })
                }
            </div>
        </div>
    }
}

export const IntentsSelector = withTranslation()(IntentsSelectorComp);
