import { makeObservable, observable } from 'mobx';
import { ProjectStore } from '@/core/stores/project.store';
import { SnippetsAPI } from '@/app/snippets/snippets.api';
import { cloneDeep } from 'lodash-es';
import { UserStore } from '@/core/stores/user.store';

export type Variable = {
    id?: number;
    fact_group_id?: number;
    name: string;
    value: any;
    is_active: boolean;
    date_created?: string;
    is_private: boolean;
    type_fact: 'snippet' | 'variable';
};

export type VariableDto = Variable & {
    type: string;
};
export class VariablesStore {
    @observable variables: VariableDto[] = [];
    @observable isLoaded = false;
    @observable isAction = false;
    @observable variableGroupId = 0;

    constructor(private projectStore: ProjectStore, private user: UserStore) {
        makeObservable(this);
    }

    private mapVariables(variables: Variable[]) {
        return variables
            .filter((variable) => {
                return variable.type_fact === 'variable';
            })
            .map((variable) => {
                const type = typeof variable.value === 'string' ? 'string' : typeof variable.value === 'number' ? 'number' : typeof variable.value === 'boolean' ? 'boolean' : 'string';

                if (!this.user.permissions.isEditProjectSettings && variable.is_private) {
                    variable.value = '******';
                }

                return { ...variable, type };
            });
    }

    async getVariables() {
        const project = this.projectStore.choosenProject;
        const variables = await SnippetsAPI.getAll(project,{
            page: 1,
            per_page: 300,
            type_fact: 'variable'
        });

        const groups = await SnippetsAPI.getGroups(project,{
            page: 1, per_page: 300, type_factgroup: 'variable'
        });

        if (groups.length > 0) {
            this.variableGroupId = groups[0].id;
        } else {
            const group = await SnippetsAPI.createGroup(project, { name: `VariablesUniqueGroup:${Math.random()}`, type_factgroup: 'variable' });
            this.variableGroupId = group.id;
        }

        this.variables = this.mapVariables(variables.results as Variable[]);

        this.isLoaded = true;
    }

    addVariable() {
        this.variables.unshift({
            name: '',
            value: '',
            is_active: true,
            is_private: false,
            type_fact: 'variable',
            fact_group_id: this.variableGroupId,
            type: 'string'
        });
    }

    async saveVariables() {
        this.isAction = true;
        const project = this.projectStore.choosenProject;
        const variablesToSave = this.variables.filter((variable) => !variable.id).map((variable) => {
            const toSave = cloneDeep(variable);
            delete toSave.type;
            return SnippetsAPI.createSnippet(project, toSave);
        });

        const variablesToUpdate = this.variables.filter((variable) => variable.id).map((variable) => {
            const toSave = cloneDeep(variable);
            delete toSave.type;
            return SnippetsAPI.patchSnippet(project, toSave);
        });

        const results = await Promise.all([...variablesToSave, ...variablesToUpdate]);

        this.variables = this.mapVariables(results as Variable[]);
        // this.variables = results;

        this.isAction = false;
    }

    async deleteVariable(variable: Variable) {
        const index = this.variables.findIndex((v) => v === variable);

        if (index !== -1) {
            this.variables.splice(index, 1);
        }

        if (variable.id) {
            this.isAction = true;
            try {
                const project = this.projectStore.choosenProject;
                await SnippetsAPI.deleteSnippet(project, variable);
                this.isAction = false;
            } catch(e) {
                this.isAction = false;
            }
        }


    }
}
