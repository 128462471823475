import { Trigger } from './Trigger';
import { Node } from '../Node';
import { Intent } from '@/app/intents/models/Intent';
import { Entity } from '@/app/entities/models/entity';
import { observable, action, makeObservable } from 'mobx';
import { Reaction } from '../reactions/Reaction';
import { State } from '../State';

export enum EntitySettingsParams {
    REQUIRED = 'required',
    OPTIONAL = 'optional',
}

type EntityLike = Pick<Entity, 'id'> & Pick<Entity, 'name'>;

export type EntitySettings = {
    entity: EntityLike;
    settings: EntitySettingsParams;
}

export type NoEntityBranch = {
    name: string;
    entity: EntityLike;
    next: State | Reaction | null;
}

export class IntentTrigger extends Trigger {
    static className = 'IntentTrigger';
    @observable intents: Array<Pick<Intent, 'id'>> = [];
    @observable entitySettings: Array<EntitySettings> = [];
    @observable noEntityBranches: Array<NoEntityBranch> = [];

    constructor() {
        super();
        makeObservable(this);
    }

    @action.bound addIntent = (intent: Intent, entities: Array<Entity>) => {
        if (!this.intents.find(i => i.id === intent.id)) {
            this.intents.push(intent);

            entities.forEach((entity) => {
                this.changeEntitySettings({entity, settings: EntitySettingsParams.OPTIONAL});
            })
        }
    }

    @action.bound removeIntent = (intent: Intent, entities: Array<Entity>) => {
        const idx = this.intents.findIndex(i => i.id === intent.id);
        if (idx >= 0) {
            this.intents.splice(idx, 1);

            entities.forEach((entity) => {
                const idx = this.entitySettings.findIndex(es => es.entity.id === entity.id);
                if (idx >= 0) {
                    this.changeEntitySettings({entity, settings: EntitySettingsParams.OPTIONAL});
                    this.entitySettings.splice(idx, 1);
                }
            })
        }
    }

    @action.bound changeEntitySettings = (entitySettings: EntitySettings) => {
        const existSettings = this.entitySettings.find(es => es.entity.id === entitySettings.entity.id);
        if (existSettings) {
            existSettings.settings = entitySettings.settings;
        } else {
            this.entitySettings.push(entitySettings);
        }

        if (entitySettings.settings === EntitySettingsParams.REQUIRED) {
            this.noEntityBranches.push({
                entity: entitySettings.entity,
                name: `No ${entitySettings.entity.name}`,
                next: null
            })
        } else {
            const {entity} = entitySettings;
            const idx = this.noEntityBranches.findIndex(es => es.entity.id === entity.id);
            if (idx >= 0) {
                this.noEntityBranches.splice(idx, 1);
            }
        }
    }

    @action.bound removeEntitySettings = (es: Pick<EntitySettings, 'entity'>) => {
        this.changeEntitySettings({...es, settings: EntitySettingsParams.OPTIONAL});

        const idx = this.entitySettings.findIndex(_es => es.entity.id === _es.entity.id);
        if (idx >= 0) {
            this.entitySettings.splice(idx, 1);
        }
    }

    @action.bound insertNotEntityNext = (name: string, next: State | Reaction) => {
        const branch = this.noEntityBranches.find(b => b.name === name);
        if (branch) {
            if (!branch.next) {
                next.parent = this;
                branch.next = next;
            } else {
                branch.next.parent = next;
                if (State.is(next)) {
                    next = next as State;
                    next.triggers[0].next = branch.next;
                } else {
                    next.next = branch.next;
                }
                branch.next = next;
            }
        }
    }

    @action.bound remove(child: Node) {
        if (this.next === child) {
            super.remove(child);
        } else {
            const branch = this.noEntityBranches.find(b => b.next === child);
            if (branch) {
                branch.next = child.next as (State | Reaction | null);
                if (child.next) {
                    child.parent = null;
                    child.next.parent = this;
                }
            }
        }
    }
}
