import React, { FC, useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { QAStore } from '../../qa.store';
import { ActionsMenu } from '@/common/components/actions-menu/ActionsMenu';
import cn from './qa.module.scss';
import pcn from '../qa-page.module.scss';
import cns from 'classnames';
import { Question } from '../../models/qa';

import { EditableText } from '@/common/components/EditableText';
import { QAForm } from '../add-qa/qa-form';

import CrossIcon from 'mdi-react/CloseIcon';
import PlusIcon from 'mdi-react/PlusIcon';
import { TextField } from '@mui/material';
import { Page } from '@/common/components/page/Page';
import {
    SaveButton
} from '@/common/components/save-button/SaveButton';
import { TestChat } from '@/app/chat/components/TestChat';
import EditIcon from 'mdi-react/EditIcon';
import { Loader } from '@/common/components/Loader';
import { RightMenu } from '@/app/components/right-menu/RightMenu';
import { IntentStore } from '@/app/intents/intent.store';
import { useTranslation } from 'react-i18next';
import { ScriptsStore } from '@/app/scripts/stores/scripts.store';
import { Permission, UserStore } from '@/core/stores/user.store';
import { PermissionsView } from '@/app/permissions/Permissions';
import { ConfirmDelete } from '@/common/components/ConfirmDelete/ConfirmDelete.component';
import { Button, Space, Switch, Input, Form, Table, Popconfirm } from 'antd';
import { BranchesOutlined, CopyOutlined, DeleteOutlined } from '@ant-design/icons';
import { QaEditStore } from '@/app/qa/components/qa/qa-edit.store';
import { useBlocker, useLocation, useNavigate, useParams } from 'react-router-dom';

const {TextArea} = Input;
import type { TableProps } from 'antd';
import { ConfirmLeave } from '@/common/components/ConfirmLeave/ConfirmLeave';


type DataType = { text: string; key: string; };

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
    editing: boolean;
    dataIndex: string;
    title: any;
    inputType: 'number' | 'text';
    record: DataType;
    index: number;
}

const EditableCell: React.FC<React.PropsWithChildren<EditableCellProps>> = ({
                                                                                editing,
                                                                                dataIndex,
                                                                                title,
                                                                                children,
                                                                                ...restProps
                                                                            }) => {
    const inputNode = <Input/>

    return (
        <td {...restProps}>
            {editing ? (
                <Form.Item
                    name={dataIndex}
                    style={{margin: 0}}
                    rules={[
                        {
                            required: true,
                            message: `Пожалуйста, введите ${title}!`,
                        },
                    ]}
                >
                    {inputNode}
                </Form.Item>
            ) : (
                children
            )}
        </td>
    );
};


interface QAProps1 {
    store?: QAStore;
    intentStore?: IntentStore;
    scriptsStore?: ScriptsStore
    userStore?: UserStore;
}

export const QA: FC<QAProps1> = inject(({
                                            qa,
                                            intentStore,
                                            scriptsStore,
                                            user
                                        }: {
    qa: QAStore,
    intentStore: IntentStore,
    scriptsStore: ScriptsStore,
    user: UserStore
}) => ({
    store: qa,
    intentStore,
    scriptsStore,
    userStore: user
}))(observer(({
                  store, intentStore, scriptsStore, userStore
              }) => {
    const [qaEditStore] = useState(() => new QaEditStore(scriptsStore, store, intentStore));
    const {t} = useTranslation();
    const navigate = useNavigate();
    const params = useParams();
    const location = useLocation();
    const [form] = Form.useForm();
    const [editingKey, setEditingKey] = useState<Question | undefined>();
    const [showDeletedRecord, setShowDeletedRecord] = useState<Question | undefined>();

    useEffect(() => {
        qaEditStore.onChangeRoute(params.id);
    }, [location]);

    const blocker = useBlocker(
        ({ currentLocation, nextLocation }) =>
            qaEditStore.isChanged && currentLocation.pathname !== nextLocation.pathname
    );


    const save = () => {
        const qa = qaEditStore.qa.questions.find((qa) => qa === editingKey);
        if (qa) {
            qa.text = form.getFieldValue('text');
        }
        form.resetFields();
        setEditingKey(undefined);
    }
    const edit = (record: Question) => {
        if (editingKey) {
            save();
        }
        form.setFieldsValue({text: record.text});
        setEditingKey(record);
    }

    const removeQuestion = (record: Question) => {
        const qaIndex = qaEditStore.qa.questions.findIndex((qa) => qa === record);
        if (qaIndex !== -1) {
            qaEditStore.qa.questions.splice(qaIndex, 1);
        }
    }

    const isEditing = (record: Question) => record === editingKey;

    const columns = [
        {
            title: 'Пример вопроса',
            key: 'text',
            dataIndex: 'text',
            editable: true,
            onCell: (record: any) => {
                return {
                    onClick: () => {
                        edit(record);
                    },
                };
            },
            render: (text: string) => {
                return <div style={{ minHeight: '32px', display: 'flex', alignItems: 'center' }}>{text}</div>
            }
        },
        {
            title: '',
            dataIndex: 'operation',
            width: '32px',
            render: (_: any, record: Question) => {
                return record === showDeletedRecord ? <Popconfirm okText={t('actions.delete')}
                                   onConfirm={() => removeQuestion(record)}
                                   cancelText={t('actions.cancel')} title="Уверены, что хотите удалить?">
                    <Button title="Удалить" icon={<DeleteOutlined/>}/>
                </Popconfirm> : null;
            },
        }
    ];


    const remove = async () => {
        await qaEditStore.onRemove();
        navigate(`/app/${params.projectId}/qa`, {replace: true});
    }

    const convertToFlow = async () => {
        const createdFlow = await qaEditStore.convertToFlow();
        navigate(`/app/${params.projectId}/flows/${createdFlow.id}`);
    }

    const onSave = async () => {
        const qa = await qaEditStore.onSave();
        if (qa.id) {
            navigate(`/app/${params.projectId}/qa/${qa.id}`, {replace: true});
        }
    }

    const mergedColumns: TableProps<Question>['columns'] = columns.map((col) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record: Question) => ({
                record,
                inputType: 'text',
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
                onClick: (e) => {
                    // @ts-ignore
                    if (e.target.tagName !== 'INPUT') {
                        edit(record); }
                    }
            }),
        };
    });

    return qaEditStore.qa && <Page
        actionMenu={userStore.permissions.isEditQa && <ActionsMenu
            right={
                <Space size={[12, 0]} wrap>
                    {
                        !qaEditStore.isNewQA && <>
                            <Space size={[4, 0]} wrap>
                                {t(qaEditStore.qa.is_active ? 'Включен' : 'Выключен')}
                                <Switch checked={qaEditStore.qa.is_active} size={'small'}
                                        onChange={qaEditStore.toggleArchive}/>
                            </Space>

                            <Button title="Дублировать" onClick={qaEditStore.duplicate}
                                    icon={<CopyOutlined/>}/>

                            <ConfirmDelete title={t('actions.delete_qa')}
                                           question={t('actions.delete_element', {name: qaEditStore.qa.name})}
                                           onConfirm={() => remove()}>
                                <Button title="Удалить" icon={<DeleteOutlined/>}/>
                            </ConfirmDelete>

                        </>
                    }

                    <PermissionsView permission={Permission.EDIT_QA}><SaveButton
                        className={cns(cn[`fill${qaEditStore.qa.answers.length}`])}
                        onClick={() => {
                            save();
                            onSave();
                        }}
                        state={qaEditStore.saveState}
                        titlesByState={qaEditStore.titlesByState}
                        disabled={!qaEditStore.canSave}/></PermissionsView>
                </Space>}
            left={
                !qaEditStore.isNewQA && <Button title={t('qa.convert_to_flow')} onClick={convertToFlow}
                                                icon={<BranchesOutlined/>}/>
            }
        />}
        rightBar={<RightMenu chat={<TestChat/>}/>}
    >

        {blocker.state === 'blocked' ? (
            <ConfirmLeave onConfirm={blocker.proceed} onCancel={blocker.reset}/>
        ) : null}

        <div className={cn.editor}>
            <div className={cn.header}>
                <EditableText text={qaEditStore.qa.name!} onEdit={qaEditStore.onEdit}
                              className={cns(pcn.title, cn.title)}
                              editable={userStore.permissions.isEditQa}
                />

            </div>
            <div className={cn.answers}>
                {
                    qaEditStore.qa.answers.map((answer, i) => (
                        <div className={cn.answer} key={i}>
                            {qaEditStore.editAnswerIndex === i &&
                                <form className={cn.editForm}>
                                    <TextField autoFocus={true} className={cn.editInput}
                                               onChange={e => answer.text = e.target.value}
                                               multiline={true}
                                               rows={1}
                                               maxRows={4}
                                               onKeyDown={qaEditStore.onKeyDown}
                                               value={answer.text}/>
                                </form>}

                            {qaEditStore.editAnswerIndex !== i && <pre className={cn.answerText}>{answer.text}</pre>}

                            <PermissionsView permission={Permission.EDIT_QA}><span className={cn.answerRemove}>
                                {qaEditStore.editAnswerIndex !== i && <span onClick={() => {
                                    qaEditStore.editAnswerIndex = i;
                                }}>
                                    <EditIcon className={cn.editQuestionIcon} size={14}/>
                                </span>}
                                <span onClick={() => {
                                    qaEditStore.qa.answers.splice(i, 1);
                                }}>
                                    <CrossIcon size={14}/>
                                </span>
                            </span></PermissionsView>
                        </div>
                    ))
                }
            </div>
            <div className={cn.addAnswerWrapper}>
                <PermissionsView permission={Permission.EDIT_QA}>
                    {qaEditStore.showQuestionForm
                        ? <QAForm onAddAnswer={qaEditStore.onAddAnswer}/>
                        : <button className={cns(cns('btn btn-link', cn.link), cn.addAnswer)}
                                  onClick={() => qaEditStore.addAnswerFormOpen = true}>{t('qa.add_another_answer')}</button>}</PermissionsView>
            </div>
            {
                (!!qaEditStore.qa.questions.length || !!qaEditStore.qa.answers.length) && <div className={cn.questions}>
                    <PermissionsView permission={Permission.EDIT_QA}>
                        <div className={cn.questionForm}>
                            <PlusIcon size={18} color={'#116FFC'}/>
                            <Form className="material-form">
                                <div className="form__form-group">
                                    <span
                                        className={cns('form__form-group-label', cn.formLabel)}>{t('qa.add_question')}</span>
                                    <div className="form__form-group-field">
                                        <div className="form__form-group-input-wrap">
                                            <TextArea
                                                value={qaEditStore.newQuestionText}
                                                onPaste={qaEditStore.onPasteQa}
                                                autoSize={{minRows: 1, maxRows: 5}}
                                                onChange={e => {
                                                    qaEditStore.newQuestionText = e.target.value
                                                }}
                                                onKeyDown={e => {
                                                    if (e.key === 'Enter' && !e.ctrlKey && !e.shiftKey) {
                                                        e.preventDefault();
                                                        qaEditStore.onQuestionAdd();
                                                    }
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </PermissionsView>
                    <div className={cn.tableWrapper}>
                        <Form form={form} component={false}>
                            <Table rowClassName={cn.row} onRow={(record) => {
                                return {
                                    onMouseEnter: () => { setShowDeletedRecord(record); }, // mouse enter row
                                    onMouseLeave: () => { setShowDeletedRecord(undefined) }, // mouse leave row
                                };
                            }} pagination={false} columns={mergedColumns} dataSource={qaEditStore.qa.questions}
                                   components={{
                                       body: {cell: EditableCell},
                                   }}/>
                        </Form>
                    </div>
                </div>
            }
        </div>
    </Page> || <Loader/>
}))
