import { State } from '@/app/scripts/models/State';
import { Reaction } from '@/app/scripts/models/reactions/Reaction';
import { Trigger } from '@/app/scripts/models/triggers/Trigger';
import { Node } from '@/app/scripts/models/Node';
import { SnippetReaction } from '@/app/scripts/models/reactions/SnippetReaction';
import { IntentTrigger } from '@/app/scripts/models/triggers/IntentTrigger';
import { TextReaction } from '@/app/scripts/models/reactions/TextReactions';
import { JumpToReaction } from '@/app/scripts/models/reactions/JumpToReaction';
import { EntityTrigger } from '@/app/scripts/models/triggers/EntityTrigger';
import { TerminateReaction } from '@/app/scripts/models/reactions/TerminateReaction';
import { ResetReaction } from '@/app/scripts/models/reactions/ResetReaction';
import { LocationReaction } from '@/app/scripts/models/reactions/LocationReaction';
import { ButtonsReaction } from '@/app/scripts/models/reactions/ButtonsReaction';
import { FileLikeReaction } from '@/app/scripts/models/reactions/FileLikeReaction';
import { RepeatReaction } from '@/app/scripts/models/reactions/RepeatReaction';
import { StartTrigger } from '@/app/scripts/models/triggers/StartTrigger';
import { AllowOutTrigger } from '@/app/scripts/models/triggers/AllowOutTrigger';
import { FallbackTrigger } from '@/app/scripts/models/triggers/FallbackTrigger';
import { UserSendTrigger } from '@/app/scripts/models/triggers/UserSendTrigger';
import { LocationTrigger } from '@/app/scripts/models/triggers/LocationTrigger';
import { FileTrigger } from '@/app/scripts/models/triggers/FileTrigger';
import { VideoTrigger } from '@/app/scripts/models/triggers/VideoTrigger';
import { AudioTrigger } from '@/app/scripts/models/triggers/AudioTrigger';
import { ImageTrigger } from '@/app/scripts/models/triggers/ImageTrigger';
import { StickerTrigger } from '@/app/scripts/models/triggers/StickerTrigger';
import { ImageReaction } from '@/app/scripts/models/reactions/ImageReaction';
import { Edge, Node as FlowNode } from 'reactflow';
import { SysIfReaction } from '@/app/scripts/models/reactions/SysIfReaction';

export type Tree =
    State
    | Reaction
    | Trigger
    | Node
    | SnippetReaction
    | IntentTrigger
    | TextReaction
    | JumpToReaction
    | EntityTrigger
    | TerminateReaction
    | ResetReaction
    | LocationReaction
    | ButtonsReaction
    | FileLikeReaction
    | RepeatReaction
    | StartTrigger
    | AllowOutTrigger
    | FallbackTrigger
    | UserSendTrigger
    | LocationTrigger
    | FileTrigger
    | VideoTrigger
    | AudioTrigger
    | ImageTrigger
    | StickerTrigger
    | ImageReaction
    | SysIfReaction;

export enum NodeTypes {
    BLUE = 'blue',
    SNIPPET = 'snippet',
    JUMPTO = 'jumpTo',
    IMAGE = 'image',
    URL = 'url',
    WHITE = 'white',
    STATE = 'state',
    LINK_LIST = 'linkList',
    SYSIF = 'sysif'
}

export type EdgeData = {
    jumpNext?: string;
};

export type Flow = { nodes: FlowNode[], edges: Edge[] };
