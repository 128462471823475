export const snippetTypes = `
            type Button {
                type: string;
                title: string;
                params: string;
            }

            class Core {
                sendText: (s: string) => void;
                sendButtons: (key: string, buttons: Button[]) => void;
                sendVideo: ({ url: string; caption?: string; quick_replies?: string[]; }) => void;
                sendImage: ({ url: string; caption?: string; quick_replies?: string[]; }) => void;
                sendAudio: ({ url: string; caption?: string; quick_replies?: string[]; }) => void;
                sendFile: ({ url: string; caption?: string; quick_replies?: string[]; }) => void;
                sendLocation: ({ lat: number; lon: number; name?: string; quick_replies?: string[]; }) => void;
                send: ({ type: string; params: any }) => void;
            }
            
            interface IEntity {
                entity: {
                    id: number;
                    name: string;
                };
            
                value: string;
            }
            
            interface Meta {
                intents: Intent[];
                entities: IEntity[];
                fully_marked: boolean;
                unfiltered_intents: Record<string, { proba: number }>;
            }
            
            interface Event {
                incoming: boolean; 
                type: string;
                timestamp: number;
                params: {
                    quick_replies?: ChatButton[];
                    [key: string]: any;
                };
                meta: Meta;
            }
            
            class Context {
                events: Event[];
                set: (key: string, value: any) => void;
                fact_name: string;
                user_id: number;
                project_id: number;
                channel_id: number;
                current: Event;
            }
            
        `;
