import React, { createContext, FC, useEffect } from 'react';
import { Modal } from 'antd';

const ReachableContext = createContext<string | null>(null);

const config = {
    title: 'Вы не сохранили изменения. Уверены, что хотите покинуть страницу?',
    okText: 'Да',
    cancelText: 'Отмена'
};

type ConfirmLeaveProps = {
    onConfirm: () => void;
    onCancel: () => void;
};
export const ConfirmLeave: FC<ConfirmLeaveProps> = ({
    onCancel,
    onConfirm
                                                    }) => {
    const [modal, contextHolder] = Modal.useModal();
    useEffect(() => {
        modal.confirm(config).then((confirmed) => {
            if (confirmed) {
                onConfirm();
            } else {
                onCancel();
            }
        }, () => {
            console.error('Error after closed ConfirmLeave');
            onCancel();
        });
    });
    return <ReachableContext.Provider value="Light">
        {contextHolder}
    </ReachableContext.Provider>
}
