import React, { FC, useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router';

import { useTranslation } from 'react-i18next';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend'

import { reaction } from 'mobx';
import { inject, observer } from 'mobx-react';

import { Auth } from './auth/Auth';
import { App } from './app/App';
import { UserStore } from './core/stores/user.store';
import { BootstrapService } from './core/bootstrap.service';
import { Loader } from '@/common/components/Loader';
import { NotFound } from '@/app/not-found/NotFound';
import { NeedAccess } from '@/app/need-access/NeedAccess';
import { PROJECT_ID_KEY } from '@/common/models/router.constants';
import { FlowDiagramPageComponent } from '@/app/scripts/components/FlowDiagram/FlowDiagramPage.component';

window.commitHash = process.env.REACT_APP_VERSION;

type RootProps = {
    user?: UserStore;
    bootstrap?: BootstrapService;
}

export const Root: FC<RootProps> = inject('user', 'bootstrap')(observer((({ bootstrap, user }) => {
    const { i18n } = useTranslation();
    useEffect(() => {
        bootstrap.bootstrap();

        i18n.changeLanguage(user.lang || 'ru');

        reaction(
            () => user.userInfo,
            () => {
                setTimeout(() => {
                    if (user && user.userInfo) {
                        i18n.changeLanguage(user!.userInfo!.language)
                    }
                });
            }
        );

        reaction(
            () => user.lang,
            lang => {
                if (lang != null) {
                    i18n.changeLanguage(lang!)
                }
            }
        );
    }, []);

    return <DndProvider backend={HTML5Backend}>
        <div className={'theme-light'}>
            <div className="wrapper">
                {
                    bootstrap!.isLoaded ?
                        <Routes>
                            <Route index element={!user!.isAuthenticate ? <Auth/> : <Navigate to={`/app/${user?.currentChoosedProjectId}/flows`}/>}/>
                            <Route path={'/auth/*'} element={!user!.isAuthenticate ? <Auth/> : <Navigate to={`/app/${user?.currentChoosedProjectId}/flows`}/>}/>
                            <Route path={'/diagram/*'}>

                                <Route path={`${PROJECT_ID_KEY}/flows/*`} element={
                                    <FlowDiagramPageComponent/>
                                }>
                                    <Route path={':id'} element={<FlowDiagramPageComponent/>}/>
                                </Route>


                            </Route>
                            <Route path={'/app/*'}>
                                <Route index element={<Navigate to={`/${user?.currentChoosedProjectId}/flows`}/>}/>
                                <Route path={`${PROJECT_ID_KEY}/*`} element={user.isAuthenticate ? <App/> : <Navigate to={'/auth'}/>}/>
                            </Route>
                            <Route path={'/404'} element={<NotFound/>}/>
                            <Route path={'/need-access'} element={<NeedAccess/>}/>
                            <Route path={'*'} element={<Navigate to={'/404'}/>}/>
                        </Routes> : <div className="load loaded"><Loader/></div>
                }
            </div>
        </div>
    </DndProvider>;
})));
