import React, { FC, useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { IntentStore } from '../intent.store';
import { Loader } from '@/common/components/Loader';
import { Intents } from './Intents';
import { EntityStore } from '../../entities/entity.store';
import { Page } from '@/common/components/page/Page';
import { TestChat } from '../../chat/components/TestChat';
import { ZeroScreen } from '@/common/components/zero-screen/ZeroScreen';
import { Navigate, Route, Routes } from 'react-router';
import cn from './IntentPage.module.scss';
import { useTranslation } from 'react-i18next';
import { SideMenuRight } from '@/common/side-menu-right/SideMenuRight';
import { IntentMenu } from './intent-menu';
import { UserStore } from '@/core/stores/user.store';
import localforage from 'localforage';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom/dist';

type IntentsPageProps1 = {
    intentStore?: IntentStore;
    entityStore?: EntityStore;
    user?: UserStore;
}
export const IntentsPage: FC<IntentsPageProps1> = inject('intentStore', 'entityStore', 'user')(observer(({ intentStore, entityStore, user }) => {

    const [isLoaded, setIsLoaded] = useState(false);
    const {t} = useTranslation();
    const navigate = useNavigate();
    const params = useParams();

    useEffect(() => {
        Promise.all([entityStore.load(), intentStore.load()]).then(async () => {
            const intentId = await localforage.getItem('currentIntentId');
            const intent = intentStore.intents.find(intent => intent.id === +intentId);
            if (intent) {
                navigate(`/app/${params.projectId}/intent/${intent.id}`);
            }

            setIsLoaded(true);
        });
    }, []);

    if (!user.permissions.isViewIntents) {
        return <Navigate to={'/'}/>;
    }

    return <>
        <SideMenuRight>
            <IntentMenu intentStore={intentStore}></IntentMenu>
        </SideMenuRight>
        {isLoaded ? <Routes>
            <Route path={'/'} element={
                <Page rightBar={<TestChat/>}>
                    <ZeroScreen title={t('intents.create_new_intent_title')} subtitle={<>
                        {t('zero_screens.subtitle')} <br/>
                        {t('intents.create_new_intent_subtitle_suffix')}</>
                    }/>
                </Page>
            }>
            </Route>
            <Route path={':id'} element={<Intents />}/>
            <Route element={<Navigate to={'/'}/>}/>
        </Routes> : <div className={cn.loader}><Loader/></div>}</>;
}))
