import React, { useEffect, useState } from 'react';
import cns from 'classnames';
import cn from './IntentSelectorItem.module.scss';
import { useDrag } from 'react-dnd';
import { DropItem } from '../models/DropItem';
import { WithTranslation, withTranslation } from 'react-i18next';
import { isReactionType } from '../models/reactions/ReactionTypes';

export interface IntentSelectorItemProps {
    name: string;
    type: string;
    disabled?: boolean;
}

type IntentSelectorItemComponentProps = IntentSelectorItemProps & WithTranslation;

class IntentSelectorItemComponentClass extends React.PureComponent<IntentSelectorItemComponentProps> {
    render() {
        return <div>
            {this.props.t(this.props.name)}
        </div>
    }
}

const IntentSelectorItemComponent = withTranslation()(IntentSelectorItemComponentClass);

export const IntentSelectorItem = ({type, name, disabled}: IntentSelectorItemProps) => {
    const item: DropItem = {
        type,
    };
    // TODO: for better time
    const [isDragged, changeIsDragged] = useState(false);
    // const [{ isDragging }, drag] = useDrag({
    //     item,
    //     collect: (monitor: any) => ({
    //         isDragging: monitor.isDragging(),
    //     }),
    //     canDrag: () => !disabled
    // });
    //
    const [{isDragging}, drag] = useDrag<unknown, unknown, { isDragging: boolean }>(() => ({
        type: item.type,
        item,
        canDrag: () => !disabled,
        collect: (monitor: any) => ({
            isDragging: monitor.isDragging(),
        })
    }))

    const classNames = {
        [cn.hide]: isDragging,
        [cn.disabled]: disabled,
        [cn.dragged]: isDragged,
        [cn.reaction]: isReactionType(type),
    };

    useEffect(() => {
        if (!isDragging) {
            changeIsDragged(false);
        }
    }, [isDragging]);

    return <div ref={drag}
                className={cns(cn.item, classNames)}
                onMouseDown={() => changeIsDragged(true)} onMouseUp={() => changeIsDragged(false)}
                onMouseLeave={() => changeIsDragged(false)}>
        <IntentSelectorItemComponent name={name} type={type}/>
    </div>;
};
