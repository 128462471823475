import React, { FC, useEffect } from 'react';
import { Channel } from '../../models/channel';
import { observer } from 'mobx-react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import { ChannelEditStore } from '@/app/connects/components/ConnectPageContent';
import { Button, Form, Space, Input, Switch } from 'antd';
import { getCurrentDomain } from '@/common/utils/get-current-domain';
// import { useMaxLength, useRegExp } from '@/common/utils/form-validators';
import { CopyOutlined } from '@ant-design/icons';

type WidgetProps = {
    channel: Channel;
    channelEditStore: ChannelEditStore;
    isManageChannels: boolean;
    onFinish: (values: any) => void;
    isRequest: boolean;
}

export const Widget: FC<WidgetProps> = observer(({
                                                                                 channel,
                                                                                 isManageChannels,
                                                                                 channelEditStore,
                                                                                 onFinish,
                                                                                 isRequest
                                                                             }) => {


    const {t} = useTranslation();
    const [form] = Form.useForm();
    channelEditStore.setCurrentEditForm(form);
    const script = `<script id="lia-widget" src="${getCurrentDomain()}/widget/widget.js?api_key=${channel.api_key}"></script>`;
    const demoUrl = `${getCurrentDomain()}/widget/demo/${channel.api_key}`;

    const onFinishForm = (values: any) => {
        onFinish(values);
    }

    useEffect(() => form.resetFields(), [channel.id]);

    // const color = Form.useWatch('color', form);
    // const text = Form.useWatch('text', form);
    // const button = Form.useWatch('button', form);
    //
    // const maxLength = useMaxLength(4096);
    // const hexValidator = useRegExp(/^#([0-9a-fA-F]{3}){1,2}$/, '#000 / #000000');

    const initialValues = {
        color: channel.params.color ?? '#d620fa',
        button: channel.params.button ?? 'button',
        text: channel.params.text,
        welcome: channel.params.welcome,
        open_onlyby_href: channel.params.open_onlyby_href ?? false,
        allow_fullscreen: channel.params.allow_fullscreen ?? false,
        show_fullscreen_icon: channel.params.show_fullscreen_icon ?? false,
        tooltip_text: channel.params.tooltip_text ?? '',
        show_close_button: channel.params.show_close_button ?? true,
        close_button_close: channel.params.close_button_close ?? true,
        auto_open_seconds: channel.params.auto_open_seconds ?? 5,
        allow_input: channel.params.allow_input ?? true,
        allow_emoji: channel.params.allow_emoji ?? false,
        allow_files: channel.params.allow_files ?? false,
        widget_background_color: channel.params.widget_background_color ?? '#FFFFFF',
        widget_background_opacity: channel.params.widget_background_opacity ?? 0,
        main_btn_bg_color: channel.params.main_btn_bg_color ?? '#04EEF3',
        font_color: channel.params.font_color ?? '#000000',
        bg_color_user: channel.params.bg_color_user ?? '#B3FDFF',
        font_size: channel.params.font_size ?? 16,
        btn_bg_color: channel.params.btn_bg_color ?? '#FFFFFF',
        btn_border_color: channel.params.btn_border_color ?? '#04EEF3',
        bot_avatar: channel.params.bot_avatar ?? '',
        show_avatars: channel.params.show_avatars ?? true,
        widget_border_radius: channel.params.widget_border_radius ?? 20,
        replay_border_color: channel.params.replay_border_color ?? '#09EEF5',
        msg_bg_color_user: channel.params.msg_bg_color_user ?? '#B3FDFF',
        msg_bg_color_bot: channel.params.msg_bg_color_bot ?? '#F2F2F2',
        quickReplay_bg_color: channel.params.quickReplay_bg_color ?? '#FFFFFF',
        quickReplay_border_color: channel.params.quickReplay_border_color ?? '#09EEF5',
        quickReplay_border_size: channel.params.quickReplay_border_size ?? 2,
        show_lia_logo: channel.params.show_lia_logo ?? true,
        init_btn_icon_color: channel.params.init_btn_icon_color ?? '#FFFFFF',
        init_btn_border_radius: channel.params.init_btn_border_radius ?? 20,
        init_btn_bg_color: channel.params.init_btn_bg_color ?? '#04EEF3',
        init_btn_flattened: channel.params.init_btn_flattened ?? false,
        ya_metrica_id: channel.params.ya_metrica_id ?? '857739374',
        widget_width: channel.params.widget_width ?? 100,
        widget_height: channel.params.widget_height ?? 90,
        widget_margin_left: channel.params.widget_margin_left ?? 0,
        widget_margin_right: channel.params.widget_margin_right ?? 0,
        widget_margin_top: channel.params.widget_margin_top ?? 100,
        widget_margin_bottom: channel.params.widget_margin_bottom ?? 0,
        widget_z_index: channel.params.widget_z_index ?? 9999,
    };

    return <Form form={form} style={{maxWidth: '600px'}} layout="vertical" onFinish={onFinishForm}
                 initialValues={initialValues}>
        <Form.Item label={t('channels.widget_code')}>
            <Space.Compact
                style={{
                    width: '100%',
                }}
            >
                <Input value={script}/>
                <CopyToClipboard text={script} onCopy={() => alert(t('actions.copied'))}>
                    <Button type="default" icon={<CopyOutlined/>}/>
                </CopyToClipboard>
            </Space.Compact>

            <Button href={demoUrl} target="_blank" type="link">{t('channels.widget_link')}</Button>
        </Form.Item>

        <Form.Item label={t('channels.open_onlyby_href')} name="open_onlyby_href">
            <Switch disabled={!isManageChannels || isRequest}/>
        </Form.Item>

        <Form.Item label={t('channels.allow_fullscreen')} name="allow_fullscreen">
            <Switch disabled={!isManageChannels || isRequest}/>
        </Form.Item>

        <Form.Item label={t('channels.show_fullscreen_icon')} name="show_fullscreen_icon">
            <Switch disabled={!isManageChannels || isRequest}/>
        </Form.Item>

        <Form.Item name="tooltip_text"
                   label={t('channels.tooltip_text')}>
            <Input disabled={!isManageChannels || isRequest}/>
        </Form.Item>

        <Form.Item label={t('channels.show_close_button')} name="show_close_button">
            <Switch disabled={!isManageChannels || isRequest}/>
        </Form.Item>

        <Form.Item label={t('channels.close_button_close')} name="close_button_close">
            <Switch disabled={!isManageChannels || isRequest}/>
        </Form.Item>

        <Form.Item name="auto_open_seconds"
                   label={t('channels.auto_open_seconds')}>
            <Input type={'number'} disabled={!isManageChannels || isRequest}/>
        </Form.Item>

        <Form.Item label={t('channels.allow_input')} name="allow_input">
            <Switch disabled={!isManageChannels || isRequest}/>
        </Form.Item>

        <Form.Item label={t('channels.allow_emoji')} name="allow_emoji">
            <Switch disabled={!isManageChannels || isRequest}/>
        </Form.Item>

        <Form.Item label={t('channels.allow_files')} name="allow_files">
            <Switch disabled={!isManageChannels || isRequest}/>
        </Form.Item>



        {/*<Form.Item help={t('channels.message_desc')} rules={[maxLength]} name="welcome"*/}
        {/*           label={t('channels.welcome_message')}>*/}
        {/*    <Input disabled={!isManageChannels || isRequest}/>*/}
        {/*</Form.Item>*/}

        {/*<Form.Item name="color"*/}
        {/*           rules={[hexValidator]}*/}
        {/*           label={t('channels.main_color')}>*/}
        {/*    <Input disabled={!isManageChannels || isRequest}/>*/}
        {/*</Form.Item>*/}

        {/*<div className={cnForm.formGroupTitle}>{t('channels.appearance')}</div>*/}

        {/*<Form.Item name="button" label={t('channels.button_mode')}>*/}
        {/*    <Radio.Group disabled={!isManageChannels || isRequest}>*/}
        {/*        <Radio value="button">{t('channels.block')}</Radio>*/}
        {/*        <Radio value="button-circle">{t('channels.bubble')}</Radio>*/}
        {/*        <Radio value="text">{t('channels.text')}</Radio>*/}
        {/*    </Radio.Group>*/}
        {/*</Form.Item>*/}

        {/*{button === 'text' && <div>*/}
        {/*    <div className={cn.chatText} style={{backgroundColor: color}}>*/}
        {/*        <span className={cn.chatHeaderName}>{text}</span>*/}
        {/*        <span className={cn.chatHeaderIcon}><ChatIcon className={cn.chatTextIcon}/></span>*/}
        {/*    </div>*/}

        {/*    <Form.Item style={{maxWidth: '286px', marginTop: '10px'}} rules={[maxLength]} name="text">*/}
        {/*        <Input disabled={!isManageChannels || isRequest}/>*/}
        {/*    </Form.Item>*/}
        {/*</div>}*/}

        {/*{button === 'button-circle' && <div style={{backgroundColor: color}}*/}
        {/*                                    className={classnames(cn.chatClosedButtonIcon, cn.chatClosedButtonCircleIcon)}>*/}
        {/*    <ChatIcon className={cn.chatIcon}/>*/}
        {/*</div>}*/}

        {/*{button === 'button' && <div style={{backgroundColor: color}} className={cn.chatClosedButtonIcon}>*/}
        {/*    <ChatIcon className={cn.chatIcon}/>*/}
        {/*</div>}*/}
    </Form>
});
