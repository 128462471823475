import { makeObservable, observable } from 'mobx';
import { Entity as EntityModel } from '@/app/entities/models/entity';
import { SaveButtonState } from '@/common/components/save-button/SaveButton';
import { cloneDeep, isEqual } from 'lodash-es';

export type ExtractorView = {
    label: string;
    value: string;
    description: string;
}
export class EntityEditStore {
    @observable selectedExtractorKey: ExtractorView | undefined;
    @observable entityModelToSave: any = {};
    @observable extractors: ExtractorView[] = [];
    @observable entity: EntityModel = {name: ''};
    prevState: EntityModel = {name: ''};
    @observable saveState: SaveButtonState = SaveButtonState.default;
    @observable titlesByState: Record<SaveButtonState, string> = {
        [SaveButtonState.default]: 'entities.save',
        [SaveButtonState.process]: 'actions.saving',
        [SaveButtonState.saved]: 'actions.saved',
        [SaveButtonState.error]: 'actions.error'
    };

    get isChanged() {
        return !isEqual(this.prevState, this.entity);
    }

    setPrevState(entity: EntityModel) {
        this.prevState = cloneDeep(entity);
    }

    constructor() {
        makeObservable(this);
    }
}
