import React, { useEffect, useState } from 'react';
import { Navigate, Route, Routes, useParams } from 'react-router-dom/dist';

import { inject, observer, Provider } from 'mobx-react';

import cn from './App.module.css';
import { Permission, UserStore } from '@/core/stores/user.store';
import { Sidebar } from './components/sidebar';
import { LeftMenuStore } from './components/sidebar/store/left-menu.store';
import { QAPage } from './qa';
import { ProjectSettings } from './project-settings/ProjectSettings';
import { ProfileSettings } from './profile-settings/ProfileSettings';
import { QAStore } from './qa/qa.store';
import { rootStore } from '@/core/stores/root.store';
import { SnippetStore } from './snippets/snippet.store';
import { SnippetPage } from './snippets/components/SnippetPage';
import { EntityStore } from './entities/entity.store';
import { EntitiesPage } from './entities/components/EntitiesPage';
import { IntentsPage } from './intents/components/IntentsPage';
import { ConnectPage } from './connects/components/ConnectPage';
import { AppStore } from './stores/app.store';
import { StatsPage } from './stats/StatsPage';
import { ConnectStore } from './connects/connect.store';
import { IntentStore } from './intents/intent.store';
import { ChatStore } from './chat/chat.store';
import { ProfileStore } from './profile-settings/profile.store';
import { LogsPage } from './logs';
import { ScriptsStore } from './scripts/stores/scripts.store';
import { ErrorsStore } from '@/core/stores/errors.store';
import Header from './components/header/Header';
import { ScriptPage } from './scripts/components/ScriptPage';
import PublishPage from './publish/PublishPage';
import { PublishPageStore } from './publish/PublishPage.store';
import { TeamPage } from '@/app/team/TeamPage';
import { TeamPageStore } from '@/app/team/TeamPage.store';
import { ActivityLogsPageStore } from '@/app/activity-logs/ActivityLogsPage.store';
import { ActivityLogsPage } from '@/app/activity-logs/ActivityLogsPage';
import { withPermission } from '@/app/permissions/Permissions';
import { changePageTitle } from '@/common/utils/change-page-title';
import { useNavigate } from 'react-router-dom';
import { VariablesPage } from '@/app/variables/VariablesPage';

type AppPageProps = {
    user?: UserStore;
};

const AppComponent: React.FC<AppPageProps> = ({user}) => {
    const params = useParams();
    const navigate = useNavigate();
    const urlProjectId = +params.projectId;

    const [isLoading, setIsLoading] = useState(true);

    const [qaStore] = useState(() => new QAStore(rootStore));
    const [snippetStore] = useState(() => new SnippetStore(rootStore));
    const [entityStore] = useState(() => new EntityStore(rootStore));
    const [connectStore] = useState(() => new ConnectStore(rootStore));
    const [intentStore] = useState(() => new IntentStore(rootStore));
    const [scriptsStore] = useState(() => new ScriptsStore(rootStore));
    const [leftMenuStore] = useState(() => new LeftMenuStore(qaStore, snippetStore, entityStore, intentStore, scriptsStore));
    const [chatStore] = useState(() => new ChatStore(rootStore));
    const [appStore] = useState(() => new AppStore(qaStore, entityStore, snippetStore, intentStore, scriptsStore, connectStore, chatStore));
    const [profileStore] = useState(() => new ProfileStore());
    const [errorsStore] = useState(() => new ErrorsStore());
    const [publishPageStore] = useState(() => new PublishPageStore(rootStore, connectStore, appStore));
    const [teamPageStore] = useState(() => new TeamPageStore(rootStore.userStore));
    const [activityLogsPageStore] = useState(() => new ActivityLogsPageStore());


    const initProject = async () => {
        const existProject = user.projects.find(project => project.id === urlProjectId) ||
            rootStore.projectStore.projects.find(project => project.id === urlProjectId);
        if (existProject) {
            try {
                await user!.chooseProject(urlProjectId);
            } catch (e) {
                console.error('Error with chooseProject', e);
            }
        }
        changePageTitle(`Лия - ${user.currentProject?.name}`);

        setTimeout(() => {
            if (Number.isNaN(urlProjectId)) {
                navigate('/404', { replace: true });
            } else if (!existProject) {
                navigate('/need-access', { replace: true });
            }
            setIsLoading(false);
        });
    }

    useEffect(() => {
        initProject();
    }, [urlProjectId]);

    if (isLoading) {
        return null;
    }

    return (
        <Provider
            appStore={appStore}
            qa={qaStore}
            snippetStore={snippetStore}
            entityStore={entityStore}
            leftMenu={leftMenuStore}
            connectStore={connectStore}
            intentStore={intentStore}
            chatStore={chatStore}
            profileStore={profileStore}
            errorsStore={errorsStore}
            scriptsStore={scriptsStore}
            publishPageStore={publishPageStore}
            teamPageStore={teamPageStore}
            activityLogsPageStore={activityLogsPageStore}
        >
            <div className={cn.app}>
                <div className={cn.header}>
                    <Header user={user}/>
                </div>
                <div className={cn.content}>
                    <Sidebar user={user}/>
                    <div className={cn.wrapper}>
                        <Routes>
                            <Route index element={<Navigate to={'flows'}/>}/>
                            <Route path={'flows/*'} element={<ScriptPage/>}/>
                            <Route path={'intent/*'} element={<IntentsPage/>}/>
                            <Route path={'connect/*'} element={<ConnectPage/>}/>
                            <Route path={'snippets/*'} element={<SnippetPage/>}/>
                            <Route path={'entities/*'} element={<EntitiesPage/>}/>
                            <Route path={'qa/*'} element={<QAPage/>}/>
                            <Route path={'stats/*'} element={<StatsPage/>}/>
                            <Route path={'logs/*'} element={<LogsPage/>}/>
                            <Route path={'publish/*'} element={<PublishPage/>}/>
                            <Route path={'profile'} element={<ProfileSettings/>}/>
                            <Route path={'project-settings/*'} element={<ProjectSettings/>}/>
                            <Route
                                path={'activity-logs'}
                                element={withPermission(Permission.MANAGE_TEAM)(<ActivityLogsPage/>)}
                            />
                            <Route
                                path={'variables'}
                                element={withPermission(Permission.MANAGE_TEAM)(<VariablesPage/>)}
                            />
                            <Route
                                path={'team'}
                                element={withPermission(Permission.MANAGE_TEAM)(<TeamPage/>)}
                            />
                            <Route path={'*'} element={<Navigate to={'/404'}/>}/>
                        </Routes>
                    </div>
                </div>
            </div>
        </Provider>
    );
}

export const App = inject('user', 'root')(observer(AppComponent));
