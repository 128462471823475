import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { User, UserRole } from '@/common/models/user';
import { AuthAPI } from '@/auth/auth.api';
import { LoginDTO } from '@/auth/models/login.dto';
import { SignupDto } from '@/auth/models/signup.dto';
import { Project } from '@/common/models/project';
import { API } from '@/common/api';
import { UserPatch } from '@/common/models/user-patch';
import { ProjectStore } from './project.store';
import _ from 'lodash';
import { Tracker } from '../analytics/tracker';
import { LogsPageStore } from '@/app/logs/logs-page.store';

export enum Permission {
    MANAGE_PROJECTS = 'manage_projects',
    EDIT_PROJECT_SETTINGS = 'edit_project_settings',
    PUBLISH_PROJECTS = 'publish_projects',
    MANAGE_COMPANY = 'manage_company',
    MANAGE_TEAM = 'manage_team',
    MANAGE_BILLING = 'manage_billing',
    MANAGE_CHANNELS = 'manage_channels',
    VIEW_STATISTICS = 'view_statistics',
    VIEW_LOGS = 'view_logs',
    VIEW_SCRIPTS = 'view_scripts',
    EDIT_SCRIPTS = 'edit_scripts',
    VIEW_INTENTS = 'view_intents',
    EDIT_INTENTS = 'edit_intents',
    VIEW_ENTITIES = 'view_entities',
    EDIT_ENTITIES = 'edit_entities',
    VIEW_QA = 'view_qa',
    EDIT_QA = 'edit_qa',
    VIEW_FACTS = 'view_facts',
    EDIT_FACTS = 'edit_facts'
}

class Permissions {
    permissions: Record<Permission, boolean>;
    constructor(permissions: string[] = [], private role?: UserRole) {
        this.permissions = permissions.reduce<Record<Permission, boolean>>((curr, prev) => {
            // @ts-ignore
            curr[prev] = true;
            return curr;
        }, {} as Record<Permission, boolean>);
    }

    get(key: Permission) {
        return this.permissions[key];
    }

    get isManageProjects() {
        return this.permissions[Permission.MANAGE_PROJECTS];
    }

    get isEditProjectSettings() {
        // return false;
        return this.permissions[Permission.EDIT_PROJECT_SETTINGS];
    }

    get isPublishProjects() {
        return this.permissions[Permission.PUBLISH_PROJECTS];
    }

    get isManageCompany() {
        return this.permissions[Permission.MANAGE_COMPANY];
    }

    get isManageTeam() {
        return this.permissions[Permission.MANAGE_TEAM];
    }

    get isManageBilling() {
        return this.permissions[Permission.MANAGE_BILLING];
    }

    get isManageChannels() {
        return this.permissions[Permission.MANAGE_CHANNELS];
    }

    get isViewStatistics() {
        return this.permissions[Permission.VIEW_STATISTICS];
    }

    get isViewLogs() {
        return this.permissions[Permission.VIEW_LOGS];
    }

    get isViewScripts() {
        // return;
        return this.permissions[Permission.VIEW_SCRIPTS];
    }

    get isEditScripts() {
        return !!this.permissions[Permission.EDIT_SCRIPTS];
    }

    get isViewIntents() {
        // return false;
        return this.permissions[Permission.VIEW_INTENTS];
    }

    get isEditIntents() {
        // return false;
        return this.permissions[Permission.EDIT_INTENTS];
    }

    get isViewEntities() {
        // return false;
        return this.permissions[Permission.VIEW_ENTITIES];
    }

    get isEditEntities() {
        return this.permissions[Permission.EDIT_ENTITIES];
    }

    get isViewQa() {
        // return false;
        return this.permissions[Permission.VIEW_QA];
    }

    get isEditQa() {
        // return false;
        return this.permissions[Permission.EDIT_QA];
    }

    get isViewFacts() {
        // return false;
        return this.permissions[Permission.VIEW_FACTS];
    }

    get isEditFacts() {
        return this.permissions[Permission.EDIT_FACTS];
    }

    get isAdmin() {
        return this.role.key === 'admin';
    }

}

export class UserStore {
    @observable isAuthenticate = false;
    @observable userInfo: User | null = null;
    @observable lang?: string;
    permissions: Permissions = new Permissions();
    // TODO: remove from userStore projects bussines logic
    constructor(private projectStore: ProjectStore) {
        makeObservable(this);
    }

    @computed get currentChoosedProjectId() {
        return this.userInfo?.chosen_project_id;
    }

    @computed get projects() {
        if (!this.userInfo) return [];
        return this.userInfo.projects.slice().sort((a, b) => {
            if (a.name > b.name) return 1;
            if (a.name < b.name) return -1;
            return 0
        });
    }


    @computed get currentProject() {
        return this.userInfo!.projects.find(p => p.id === this.currentChoosedProjectId)!;
    }

    @action.bound
    async updateProject(projectId: string, project: Project) {
        try {
            const savedProject = await API.put<Project>(`/projects/${projectId}`, project);
            const cachedProject = this.userInfo!.projects.find(projectItem => projectItem.id === savedProject.id);
            if (cachedProject) {
                cachedProject.name = savedProject.name;
            }
        } catch (e) {
            console.error(e);
        }
    }

    @action.bound
    async chooseProject(projectId: number) {
        await AuthAPI.patchUser({
            chosen_project_id: projectId
        });

        await this.projectStore.chooseProject(projectId);

        runInAction(() => {
            this.userInfo!.chosen_project_id = projectId;
        });
    }

    @action.bound
    async pushProject(name: string, templateProjectId?: number) {
        try {
            const project = await API.post<Project>('/projects', {
                name,
                from_template: templateProjectId
            });
            this.userInfo!.projects.push(project);
        } catch (e) {
            return e;
        }
    }

    @action.bound updateUser(user: UserPatch) {
        return AuthAPI.patchUser(user);
    }

    @action
    async auth(user: User) {
        await this.fillUserData(user);
    }

    @action.bound
    private async fillUserData(user: User) {
        const _user = _.cloneDeep(user);
        delete _user.projects;
        Tracker.registerUser(_user);
        runInAction(() => {
            this.userInfo = user;
            this.permissions = new Permissions(user.permissions, user.role);
            this.lang = user.language;
            this.projectStore.setProjects(user.projects);
        });
        await this.chooseProject(user.chosen_project_id || this.projects[0].id);
        this.isAuthenticate = !!user;
    }

    @action.bound
    async login(data: LoginDTO) {
        const userInfo = await AuthAPI.login(data);
        LogsPageStore.resetSavedFilter();
        await this.fillUserData(userInfo);
    }

    @action.bound
    async signUp(data: SignupDto) {
        if (['ru', 'en'].includes(window.navigator.language)) {
            data.language = window.navigator.language;
        }
        const user = await AuthAPI.signUp(data);
        await this.fillUserData(user);
    }

    @action
    async logout() {
        try {
            await AuthAPI.logout();
        } finally {
            runInAction(() => {
                this.projectStore.clearTimeouts();
                this.isAuthenticate = false;
                this.userInfo = null;
                this.permissions = new Permissions();
                Tracker.reset();
            });
        }
    }

    @action
    async load() {
        try {
            const user = await AuthAPI.getUser();
            runInAction(() => {
                this.isAuthenticate = true;
                this.userInfo = user;
                this.permissions = new Permissions(user.permissions, user.role);
                this.projectStore.setProjects(user.projects);
            });
            await this.projectStore.chooseProject(user.chosen_project_id);
        } catch (e) {
            console.error(e);
        } finally {
            if (this.userInfo) {
                const _user = _.cloneDeep(this.userInfo);
                delete _user!.projects;
                Tracker.registerUser(_user);
            }
        }
    }
}
